<template>
  <div class="selector-container">
    <div
      @click="onGameSelected(index)"
      :class="getClass(game, index)"
      v-for="(game, index) of games"
      :key="index"
    >
      <v-img        
        alt="Logo"
        :class="game.imageClass"
        :src="game.img"
        transition="scale-transition"
      />
     
      
      <div v-if="game.lock && game.img != ''" class="game-locked">
       <p class="lock-text">PRÓXIMAMENTE</p>
      </div>
     
    </div>
  </div>
</template>
<script>
import nt_lang from '../store/nt_language.json';
import tm_lang from '../store/tm_language.json';
import lt_lang from '../store/lt_language.json';

export default {
  data() {
    return {
      lblTitle: "SELECCIONA UN JUEGO",
      games: [],
    };
  },
  created() {

      if(!this.$store.getters.isGameLangSet){
      if(this.currentLang == undefined && localStorage.getItem('ul') == undefined){
        if(navigator.language.search('es') > -1)
          {      
            this.$store.commit("setCurrentLanguage",'ESP_AR');
            this.$store.commit("setCurrency",'₡');

          }else if(navigator.language.search('en') > -1){

            this.$store.commit("setCurrentLanguage",'ENG_US');
            this.$store.commit("setCurrency",'$');

          }
      }else if(localStorage.getItem('ul')){
         this.$store.commit("setCurrentLanguage",localStorage.getItem('ul'));
         if(localStorage.getItem('uc')){
            this.$store.commit("setCurrency",localStorage.getItem('uc'));
         }

      }

      this.$store.dispatch("setLanguageFile", lt_lang);
    }
    
    this.games = [
      {
        game: "nuevosTiempos",      
        /* img: "assets/img/nuevosTiemposLogo.2dd7955d.png", */
        img: "/static/dist/img/nuevosTiemposLogo.2dd7955d.png",
        imageClass:"selector-game-logo",
        lock: false,
        langFile:nt_lang 
      },
      { game: "tresMonazos",    
       img: "/static/dist/img/tresMonazosLogo.18016002.png",
        lock: false, imageClass:"selector-game-logo-small",langFile:tm_lang  },
      { game: "lotto",
       img: "/static/dist/img/lottoLogo.6e8555b5.png",       
       lock: false, imageClass:"selector-game-logo",langFile:lt_lang  },
      { game: "other", img: "", lock: true , imageClass:"selector-game-logo"},
    ];
  },
  mounted(){
   
    
    this.$store.dispatch("setTitle", 'Loteria');
  },
  methods: {
   
   
    getClass(game, index) {
      var className = "";
      if (game.lock) {
        if (index % 3 == 0) {
          className = "game-image-lock-2";
        } else {
          className = "game-image-lock-1";
          /*  if (index % 4 == 0) {
            className = "game-image-lock-2";
          }
          if (index % 6 == 0) {
            className = "game-image-lock-2";
          } */
        }
      } else {
        return (className = "game-image-unlock");
      }
      return className;
    },
    onGameSelected(index) {
      //console.log("Game Selected " + this.games[index]);
      if (!this.games[index].lock) {
        this.$store.dispatch("setSelectedGame", this.games[index])
       
        
        .then( async res  =>  {
            await this.$store.dispatch("setLanguageFile", this.games[index].langFile);
            this.$router.push(this.games[index].game);
        });      
        
      }
    },
  },
};
</script>
