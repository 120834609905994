<template>
  <div class="game-container">
    <div class="toPrint-tm">
        <img
        id="ticketImage"
        alt="Logo"
        style="max-width:100%;"
        src="/static/dist/img/tresMonazosLogo.18016002.png"
       
        
      />

       <img
            id="apkTicketImageTm"
            alt="Logo"
            style="max-width:100%;"
            src="/static/dist/img/tresMonazosBNW.png"
           
            
          />

        <canvas id="apkLogoCanvasTm" width="150" height="150" />
      <div id="printTicket" class="print-ticket">
        

        <table class='table-ticket-numbers'>
            <tr>
              <td class="index-column font-weight-bold">#</td>
              <td class="game-column font-weight-bold">{{getPhrase('ticket_game')}}</td>
              <td class="numbers-column font-weight-bold">{{getPhrase('ticket_number')}}</td>                
              <td class="date-column font-weight-bold">{{getPhrase('ticket_date')}}</td>                
              <td class="bet-column font-weight-bold">{{getPhrase('ticket_amount')}}</td>
              
            <tr>
            <tr  v-for="(num, index) of selectedNumbers"
            :key="index">
              <td class="index-column">{{ getIndex(index) }}</td>
              <td class="game-column"> {{ num.type | capitalized }} {{ getTicketBlindRooster(num) }}</td>
              <td class="numbers-column"><span class="ticket-number"  >{{ num.number }}</span></td>                  
              <td class="date-column">{{ num.date }}</td>                  
              <td class="bet-column"> {{ num.bet | addCurrency(currency, true) }}</td>
              
            </tr>
          
        </table>
        <table class="table-ticket-totals">
            <tr>
              <td class="total-label">
               {{getPhrase('ticket_total_amount')}}
              </td>
              <td class="total-amount">
                {{ getTotal | addCurrency(currency, true) }}
              
              </td>
            </tr>
            <tr>
              <td class="total-label">
                {{getPhrase('ticket_raffles_amount')}}
              </td>
              <td class="total-amount">
                {{ selectedNumbers.length }}
              </td>
            </tr>
          
        </table>
        <div class="good-luck-message">
          <p>{{getPhrase('ticket_good_luck')}}</p>
        </div>
        <table class="table-ticket-info">
            <tr>
              <td class="total-label">
                {{getPhrase('ticket_play_date')}}
              </td>
              <td class="total-amount">
                {{ getPrintDate() }}
              </td>
            </tr>
            <tr>
              <td class="total-label">
                 {{getPhrase('ticket_terminal_label')}}
              </td>
              <td class="total-amount">
                1208-001
              </td>
            </tr>
        </table>
        <qrcode-vue class="qr-ticket" :value="qrValue" :size="qrSize" :level="qrLevel" />
        <p>{{qrValue}}</p>
        <p>{{getPhrase('ticket_print_date')}}  {{getPrintDate()}}</p>
      </div>
    </div>
    <div v-if="!portrait" class="landscape">
      <v-dialog persistent v-model="printDialog" width="50%">
         <div v-if="printing"  class="printing-card">
          <v-icon class="print-icon">mdi-printer</v-icon> 
          <p>{{getPhrase('modal_printing')}}</p>     
          <div class="stage">
              <div class="dot-flashing"></div>
          </div>
        </div>
        <v-card class="print-dialog-tm">
          <div class="dialog-title">
            <p class="dialog-label">{{ getPhrase('print_modal_title') }}</p>
          </div>

          <div class="info-panel-display">
            <div
              v-for="(num, index) of selectedNumbers"
              :key="index"
              class="info-panel-number"
            >
              <div class="number-container">
                <div class="index">{{ getIndex(index) }}</div>
                <div class="number">{{ num.number }}</div>
              </div>

              <div v-if="num.blindRooster === ''" class="type">
                {{ num.type | capitalized }}
                <div class="date">{{ num.date }}</div>
              </div>
              <div v-else class="type">
                {{ num.type | capitalized }} ({{ num.blindRooster }})
                <div class="date">{{ num.date }}</div>
              </div>

              <div v-if="num.reventadoBet === ''" class="bet">
                {{ num.bet | addCurrency(currency, true) }}
              </div>
              <div v-else class="bet">
                {{ num.bet | addCurrency(currency, true) }}
                <p class="label-reventado">(R)</p>
                {{ num.reventadoBet | addCurrency(currency, true) }}
              </div>
            </div>
          </div>
          <v-divider class="dialog-divider"></v-divider>
          <div class="resumen-dialog">
            <p class="dialog-resumen-title">{{ getPhrase('ticket_resume_label') }}</p>
            <!-- <p class="dialog-resumen-totals">{{ lblDialogRafflesTimes }}</p>
          <p class="dialog-resumen-value">{{ getRaffleTimes }}</p>
          <p class="dialog-resumen-totals">{{ lblDialogRafflesDays }}</p>
          <p class="dialog-resumen-value">{{ getRaffleDays }}</p> -->
            <p class="dialog-resumen-totals">
              {{ getPhrase('ticket_total_amount_label') | addCurrency(currency, false) }} {{ getTotal }}
            </p>
            <!-- <p class="dialog-resumen-value">{{ getTotal }}</p> -->
          </div>
          <div class="controls-dialog">
            <v-btn color="error" class="button-dialog" @click="onDialogCancel">
              {{ getPhrase('cancel_label_button') }}
            </v-btn>
            <v-btn
              color="success"
              class="button-dialog"
              @click="onDialogAccept"
            >
              {{ getPhrase('accept_label_button') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <div class="game-col-1">
        <div class="info-panel-container">
          <info-panel
            ref="info"
            :numbers="selectedNumbers"
            :currency="currency"
            :totalBet="getTotal"
            :raffleDays="getRaffleDays"
            :raffleTimes="getRaffleTimes"
            v-on:clear-numbers="onClear"
            v-on:delete-number="onDelete"
          />
        </div>
      </div>
      <div class="game-col-2">
        <div class="display-panel-container">
          <display-panel
            ref="displayPanel"
            :currentNumber="currentNumber"
            :amountBet="amountBet"
            :currency="currency"
            :showBetConfirm="showBetConfirm"
            :disabledConfirmarBet="disabledConfirmarBet"
            :disabledReventado="disabledReventado"
            :reset="reset"
            :alertType="alertType"
            :alertText="alertText"
            :reventadoAmountBet="reventadoAmountBet"
            v-on:update-raffle-type="onUpdateRaffleType"
            v-on:update-reventado="onUpdateRevetando"
            v-on:on-confirmar-bet="onConfirmarBet"
            v-on:on-bet="onBet"
            v-on:on-reventado-bet="onReventadoBet"
          />
        </div>
        <div class="control-panel-container">
          <control-panel
            :days="days"
            :times="times"
            :valueButtons="valueButtons"
            :betButtons="betButtons"
            :currency="currency"
            :reset="resetDefault"
            :setDefaultBet="defaultBet"
            :deactivateDefaultBets="deactivateDefaultBets"
            :setDefaultDay="defaultDay"
            :setDefaultTime="defaultTime"
            :resetCustomBet="resetCustomBet"
            :disabledMatutino="disabledMatutino"
            :acceptDisabled="acceptDisabled"            
            :disabledBetAmounts="disabledBetAmounts"
            :disabledControls="disabledControls"
            :disabledValueControls="disabledValueControls"
            :disabledDateControls="disabledDateControls"
            :lblAceptar="lblControlAceptar"           
            :matutinoTime="MATUTINO_TIME"
            :nocturnoTime="NOCTURNO_TIME"
            :currentRaffleDay="currentRaffleDay"
            :disabledPrint="getPrintDisabledStatus()"
            v-on:keyboard-update="onKeyboardUpdate"
            v-on:update-bet="onUpdateBet"
            v-on:update-custom-bet="onUpdateCustomBet"
            v-on:on-update-raffle-day="onUpdateRaffleDay"
            v-on:on-update-raffle-time="onUpdateRaffleTime"
            v-on:on-blind-rooster-tap="onBlindRooster"
            v-on:on-aceptar-tap="onAceptar"
            v-on:print="onPrint"
          />
        </div>
        <div class="footer">
          <p class="footer_text">
            {{ getPhrase('powered_by') }} 
          </p>
           <img
           
            alt="Logo"
            style="max-width:100%;"
            class="logo-footer"
            src="/static/dist/img/logo_footer.svg"
           
            
          />
          <p class="footer_text">{{ lblFooter }}</p>
        </div>
         
       
      </div>
    </div>
    <div v-if="portrait" class="portrait">
      <v-dialog persistent v-model="printDialog" width="100%">

        <div v-if="printing"  class="printing-card">
          <v-icon class="print-icon">mdi-printer</v-icon> 
          <p>{{getPhrase('modal_printing')}}</p>     
          <div class="stage">
              <div class="dot-flashing"></div>
          </div>
        </div>
      
        <v-card class="print-dialog-portrait-tm">
          <div class="dialog-title">
            <p class="dialog-label">{{ getPhrase('print_modal_title') }}</p>
          </div>

          <div class="info-panel-display">
            <div
              v-for="(num, index) of selectedNumbers"
              :key="index"
              class="info-panel-number"
            >
              <div class="number-container">
                <div class="index">{{ getIndex(index) }}</div>
                <div class="number">{{ num.number }}</div>
              </div>

              <div v-if="num.blindRooster === ''" class="type">
                {{ num.type | capitalized }}
                <div class="date">{{ num.date }}</div>
              </div>
              <div v-else class="type">
                {{ num.type | capitalized }} ({{ num.blindRooster }})
                <div class="date">{{ num.date }}</div>
              </div>

              <div v-if="num.reventadoBet === ''" class="bet">
                {{ num.bet | addCurrency(currency, true) }}
              </div>
              <div v-else class="bet">
                {{ num.bet | addCurrency(currency, true) }}
                <p class="label-reventado">(R)</p>
                {{ num.reventadoBet | addCurrency(currency, true) }}
              </div>
            </div>
          </div>
          <v-divider class="dialog-divider"></v-divider>
          <div class="resumen-dialog">
            <p class="dialog-resumen-title">{{ getPhrase('ticket_resume_label') }}</p>
            <!-- <p class="dialog-resumen-totals">{{ lblDialogRafflesTimes }}</p>
          <p class="dialog-resumen-value">{{ getRaffleTimes }}</p>
          <p class="dialog-resumen-totals">{{ lblDialogRafflesDays }}</p>
          <p class="dialog-resumen-value">{{ getRaffleDays }}</p> -->
            <p class="dialog-resumen-totals">
              {{ getPhrase('ticket_total_amount_label') | addCurrency(currency, false) }} {{ getTotal }}
            </p>
            <!-- <p class="dialog-resumen-value">{{ getTotal }}</p> -->
          </div>
          <div class="controls-dialog">
            <v-btn color="error" class="button-dialog" @click="onDialogCancel">
              {{ getPhrase('cancel_label_button') }}
            </v-btn>
            <v-btn
              color="success"
              class="button-dialog"
              @click="onDialogAccept"
            >
              {{ getPhrase('accept_label_button') }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <!-- <v-dialog v-model="showPdf" width="500">
        <pdf v-if="showPdf" :pdf="pdfUrl"></pdf>
        <v-btn>Cerrar</v-btn>
      </v-dialog> -->
      <div class="portrait-display-panel-container">
        <display-panel-portrait
          ref="displayPanelPortrait"
          :currentNumber="currentNumber"
          :amountBet="amountBet"
          :currency="currency"
          :showBetConfirm="showBetConfirm"
          :disabledConfirmarBet="disabledConfirmarBet"
          :disabledReventado="disabledReventado"
          :reset="reset"
          :currentRaffleType="currentRaffleType"
          :reventadoAmountBet="reventadoAmountBet"
          v-on:update-reventado="onUpdateRevetando"
          v-on:on-confirmar-bet="onConfirmarBet"
          v-on:on-bet="onBet"
          v-on:on-reventado-bet="onReventadoBet"
        />
      </div>
      <div class="portrait-info-panel-container">
        <info-panel-portrait
          ref="infoPortrait"
          :numbers="selectedNumbers"
          :currency="currency"
          :totalBet="getTotal"
          :raffleDays="getRaffleDays"
          :raffleTimes="getRaffleTimes"
          v-on:clear-numbers="onClear"
          v-on:delete-number="onDelete"
        />
      </div>
      <div class="portrait-control-panel-container">
        <control-panel-portrait
          :days="days"
          :times="times"
          :valueButtons="valueButtons"
          :betButtons="betButtons"
          :currency="currency"
          :alertType="alertType"
          :alertText="alertText"
          :reset="resetDefault"
          :setDefaultBet="defaultBet"
          :deactivateDefaultBets="deactivateDefaultBets"
          :setDefaultDay="defaultDay"
          :setDefaultTime="defaultTime"
          :clearDays="clearDays"
          :resetCustomBet="resetCustomBet"
          :acceptDisabled="acceptDisabled"            
          :disabledBetAmounts="disabledBetAmounts"
          :disabledControls="disabledControls"
          :disabledValueControls="disabledValueControls"
          :disabledDateControls="disabledDateControls"
          :lblAceptar="lblControlAceptar"  
          :showAlertArrow="showAlertArrow"        
          :disabledMatutino="disabledMatutino"
          :matutinoTime="MATUTINO_TIME"
          :nocturnoTime="NOCTURNO_TIME"
          :currentRaffleDay="currentRaffleDay"
          :currentRaffleTime="currentRaffleTime"
          :disabledPrint="getPrintDisabledStatus()"
          v-on:keyboard-update="onKeyboardUpdate"
          v-on:update-bet="onUpdateBet"
          v-on:update-custom-bet="onUpdateCustomBet"
          v-on:on-update-raffle-day="onUpdateRaffleDay"
          v-on:on-update-raffle-time="onUpdateRaffleTime"
          v-on:on-blind-rooster-tap="onBlindRooster"
          v-on:on-aceptar-tap="onAceptar"
          v-on:print="onPrint"
          v-on:update-raffle-type="onUpdateRaffleType"
           v-on:show-dates="onShowDates"
          v-on:acept-dates="onAceptDates"
          v-on:cancel-dates="onCancelDates"
        />
      </div>
    </div>
  </div>
</template>
<script>
import "../assets/css/tresMonazos.scss";
import InfoPanel from "../components/tresMonazos/InfoPanelTM.vue";
import DisplayPanel from "../components/tresMonazos/DisplayPanelTM.vue";
import ControlPanel from "../components/tresMonazos/ControlPanelTM.vue";
import InfoPanelPortrait from "../components/tresMonazos/InfoPanelPortraitTM.vue";
import DisplayPanelPortrait from "../components/tresMonazos/DisplayPanelPortraitTM.vue";
import ControlPanelPortrait from "../components/tresMonazos/ControlPanelPortraitTM.vue";


import QrcodeVue from 'qrcode.vue'
import TicketData from "../helpers/ticketData.js"


import jsPDF from 'jspdf' 
import html2canvas from "html2canvas"
import { mapState, mapGetters } from "vuex";
import tm_lang from '../store/tm_language.json';

export default {
  components: {
    "info-panel": InfoPanel,
    "display-panel": DisplayPanel,
    "control-panel": ControlPanel,
    "info-panel-portrait": InfoPanelPortrait,
    "display-panel-portrait": DisplayPanelPortrait,
    "control-panel-portrait": ControlPanelPortrait,
    QrcodeVue
  },
  data() {
    return {
      game: null,     
      printing:false,
      MAX_BET: 2000000,
      MIN_BET: 100,
      MAX_NUMBERS: 10,
      MATUTINO_TIME: "12:00",
      NOCTURNO_TIME: "19:00",
      currency: "₡",
      selectedNumbers: [],
      valueButtons: [],
      betButtons: [],
      days: [],
      times: [],
      currentNumber: "",
      currentRaffleType: "",
      currentRaffleDay: "",
      currentRaffleTime: "",
      currentPadTagert: "",
      reventado: false,
      reset: false,
      resetDefault: false,
      amountBet: 0,
      reventadoAmountBet: 0,
      alertType: "info",
      alertText: "",
      printDialog: false,
      lblAceptar: "Aceptar",
      lblCancelar: "Cancelar",
      lblPrintDialogTitle: "¿Desea imprimir el ticket?",
      lblDialogRafflesDays: "Dias: ",
      lblDialogRafflesTimes: "Horario: ",
      lblDialogTotal: "Total en ",
      lblResumeDialogtitle: "Resumen:",
      lblFooter: "",
      acceptDisabled: false,
      disabledConfirmarBet: true,
      disabledReventado: false,
      duplicateNumber: false,
      blindRooster: false,
      showBetConfirm: false,
      resetCustomBet: "",
      previousBet: "",
      printPdf: true,
      portrait: false,
      showPdf: false,



      lblControlAceptar: 'Ingresar',
      confirmBet:false,
      BET_TYPE_STATE:'betTypeState',
      BET_AMOUNT_STATE:'betAmountState',
      REVENTADO_BET_AMOUNT_STATE:'reventadoBetAmountState',
      RAFFLE_NUMBER_STATE:'raffleNumberState',         
      RAFFLE_DATE_STATE:'raffleDateState',         
      INSERT_RAFFLE_STATE:'insertDateState',         
      currentState:null,
      disabledBetTypes:false,
      disabledBetAmounts:false,
      disabledControls:false,
      disabledValueControls:false,
      disabledDateControls:false,
      customBet:false,
      selectedDays:[],
      defaultBet:false,
      defaultDay:false,
      defaultTime:false,
      showCancel:false,
      showAlertArrow:false,
      deactivateDefaultBets:false,
      qrValue:'125646-45616465',
      qrSize:130,
      qrLevel:'H',
      disabledMatutino:false,
      clearDays:false,     
    };
  },
  created() {

    

    if(!this.$store.getters.isGameLangSet){
      if(this.currentLang == undefined && localStorage.getItem('ul') == undefined){
        if(navigator.language.search('es') > -1)
          {      
            this.$store.commit("setCurrentLanguage",'ESP_AR');
            this.$store.commit("setCurrency",'₡');

          }else if(navigator.language.search('en') > -1){

            this.$store.commit("setCurrentLanguage",'ENG_US');
            this.$store.commit("setCurrency",'$');

          }
      }else if(localStorage.getItem('ul')){
         this.$store.commit("setCurrentLanguage",localStorage.getItem('ul'));
         if(localStorage.getItem('uc')){
            this.$store.commit("setCurrency",localStorage.getItem('uc'));
         }

      }
      this.$store.dispatch("setLanguageFile", tm_lang);
    }
    window.addEventListener("resize", this.resolutionUpdated);
    this.lblControlAceptar = this.$store.getters.getPhrase('enter_label_button'),
    this.resolutionUpdated();
    this.currentPadTagert = "currentNumber";
    this.valueButtons = [
      "7",
      "8",
      "9",
      "4",
      "5",
      "6",
      "1",
      "2",
      "3",
      "<",
      "0",
      this.$store.getters.getPhrase('delete_label_button'),
    ];
    this.betButtons = ["100", "200", "300", "400", "500", "1000", "2000"];
    this.times = ["M", "N"];
    this.days = ["HOY", "LUN", "MAR", "MIÉ", "JUE", "VIE", "SAB", "DOM"];
    this.amountBet = 100;
    this.currentNumber = "";
    this.alertText = "";
    this.currentRaffleType = "orden";
    this.lblFooter = "v" + this.version;
    this.currency = this.$store.getters.currency;
   
    this.setDates();
   
    this.currentState = this.BET_TYPE_STATE;

     if(!this.checkLimitTime()){
       this.currentRaffleTime = this.times[1];
    }else{
       this.currentRaffleTime = this.times[0];
    }

    this.currentRaffleDay = this.days[0];
    this.selectedDays = [this.currentRaffleDay];
    this.selectedDays[0].time = this.currentRaffleTime;
    
  },
  destroyed() {
    window.removeEventListener("resize", this.resolutionUpdated);
  },
  mounted() {
    console.log('TresMonazos mounted ',this.onApk ,this.apkPrinterReady );
    if(this.onApk && !this.apkPrinterReady){
        this.$printer.initializePrinter(this.onApkPrinterReady,this.onApkPrinterReject);
    }
    this.$store.dispatch("setTitle", "Tres Monazos");
    this.setRaffleAlert();
    if (document.getElementById("version")) {
      var element = document.getElementById("version");
      var version = element.value;
      this.$store.dispatch("setVersion", version);
      this.lblFooter = "v" + this.version;
      element.parentNode.removeChild(element);
    }
    if (document.getElementById("printPdf")) {
      var element = document.getElementById("printPdf");
      if (element.value == "True") {
        this.printPdf = true;
      } else {
        this.printPdf = false;
      }
      element.parentNode.removeChild(element);
    }

    this.defaultTime = !this.defaultTime;
    this.defaultDay = !this.defaultDay;
    this.defaultBet = !this.defaultBet;
  },

  computed: {
    ...mapState({
      pdfUrl: (state) => state.pdfUrl,
      version: (state) => state.version,
      onApk: (state) => state.onApk,
      apkPrinterReady: (state) => state.apkPrinterReady,
    }),
    getTotal() {
      var total = 0;
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        total += Number(this.selectedNumbers[i].bet);
        
      }

      if (total == 0) {
        return "";
      } else {
        return total;
      }
    },
    getRaffleDays() {
      var days = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var day = String(this.selectedNumbers[i].raffleDay).toLowerCase();
        day = day.charAt(0).toUpperCase() + day.slice(1);
        if (days.indexOf(day) < 0) {
          days.push(day);
        }
      }
      if (days.length == 0) {
        return "";
      } else {
        return days.toString();
      }
    },
    getRaffleTimes() {
      var times = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var time = String(this.selectedNumbers[i].raffleTime).toLowerCase();
        time = time.charAt(0).toUpperCase() + time.slice(1);
        if (times.indexOf(time) < 0) {
          times.push(time);
        }
      }
      if (times.length == 0) {
        return "";
      } else {
        return times.toString();
      }
    },
    getRaffles() {
      var raffes = [];
      for (let i = 0; i < this.selectedNumbers.length; i++) {
        var type = String(this.selectedNumbers[i].type).toLowerCase();
        var raffleType = "";
        //Para pasarle al ticket la abreviacion
        switch (String(type).toLowerCase()) {
          case "orden":
            raffleType = "ORD";
            break;
          case "desorden":
            raffleType = "DES";
            break;
          case "ord/des":
            raffleType = "ORD/DES";
            break;
          case "ultimos":
            raffleType = "ULT";
            break;

          default:
            break;
        }
        if (raffes.indexOf(raffleType) < 0) {
          raffes.push(raffleType);
        }
      }

      return raffes;
    },
     ...mapGetters([
      'getPhrase',     
      // ...
    ])
  },
  filters: {
    capitalized(val) {
      return val.charAt(0).toUpperCase() + val.slice(1);
    },
    addCurrency(val, currency, before) {
      if (val != "0" && val != "") {
        if (before) {
          return currency + " " + val;
        } else {
          return val + " " + currency;
        }
      } else {
        return val;
      }
    },
  },
  methods: {
    onApkPrinterReady(){
      console.log('onApkPrinterReady')
      this.$store.dispatch("setApkPrinterReady", true);
    },
    onApkPrinterReject(){
      console.log('onApkPrinterReject')
      this.$store.dispatch("setApkPrinterReady", false);
    },
     getTicketBlindRooster(num){
      if(num.blindRooster.length === 0){
        return '';
      }else{
        return '('+num.blindRooster+')';
      }
    },
     checkRaffleTimes(){

  
   
      if (!this.checkLimitTime() && this.selectedDays.length === 1 && String(this.selectedDays[0].weekday).toLowerCase() === this.$store.getters.getPhrase('today_label')) {
        this.disabledMatutino = true;
      } else {
        this.disabledMatutino = false;
       
      }
     
    },

    checkLimitTime(){
      let currentDate = new Date();      
      let hours = currentDate.getHours();
      let minutes = currentDate.getMinutes();
      let seconds = currentDate.getSeconds();
      let nowTime = hours+':'+minutes+':'+seconds;
      let limitTime = this.MATUTINO_TIME+':00';    

      let nowDate = new Date(currentDate.getTime());
      nowDate.setHours(nowTime.split(":")[0]);
      nowDate.setMinutes(nowTime.split(":")[1]);
      nowDate.setSeconds(nowTime.split(":")[2]);

      let limitDate = new Date(currentDate.getTime());
      limitDate.setHours(limitTime.split(":")[0]);
      limitDate.setMinutes(limitTime.split(":")[1]);
      limitDate.setSeconds(limitTime.split(":")[2]);  

         
     /*  console.log('nowDate '+nowDate);
      console.log('limitDate '+limitDate);
      console.log('this.selectedDays.length '+this.selectedDays.length);
      console.log('this.selectedDays[0].weekday '+this.selectedDays[0].weekday); */

      if (nowDate > limitDate){
        return false;
      }else{
        return true;
      }
    },
     onCreateTicket(){

        if(this.onApk){
          try {

            this.apkPrint();

          } catch (error) {

            alert('Print Error '+error);

          } 
        }else{
      
          this.printing = true;
          const minDocHeight = 80;
          const maxDocHeight = 110;
          let docWidth = 55; 
          let docHeight = maxDocHeight; 

          if(this.selectedNumbers.length < this.MAX_NUMBERS){
            docHeight = minDocHeight + (this.selectedNumbers.length  * 3);
          }
  
          let doc = new jsPDF('p', 'mm', [docHeight, docWidth]); //310mm high 55mm wide
          let ref = this;
          let scaleCanvas = null;      
          
          
          if(!this.portrait){
            scaleCanvas = 1.5;
          }
         

          html2canvas(document.getElementById("printTicket"),{scale:scaleCanvas, width: document.getElementById("printTicket").offsetWidth,
          height: document.getElementById("printTicket").offsetHeight}).then(function(canvas) {

              const imgData = canvas.toDataURL('image/png');            
              

              const pageWidth = doc.internal.pageSize.getWidth();
              const pageHeight = doc.internal.pageSize.getHeight();

              const widthRatio = pageWidth / canvas.width;
              const heightRatio = pageHeight / canvas.height;
              const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

              const canvasWidth = canvas.width * ratio;
              const canvasHeight = canvas.height * ratio;

              const marginX = (pageWidth - canvasWidth) / 2;
              const marginY = (pageHeight - canvasHeight) / 2;

              let imageHeight = pageWidth * 0.250;
              let imageWidth = pageWidth * 0.250;
          

              const imageMarginX = (pageWidth - imageWidth) / 2;

              const fileName = 'TresMonazos-'+ref.getPrintDate()+'.pdf';

              const gap = 1;
              
              
              doc.addImage(document.getElementById("ticketImage"), 'PNG', imageMarginX, gap,imageWidth,imageHeight);
              doc.addImage(imgData, 'PNG', 0, imageHeight + gap ,canvasWidth,canvasHeight);

              
            
              doc.save(fileName);

              setTimeout(() => {
                ref.printing = false;
                ref.printDialog = false;          
                ref.onClear();
              }, 1000);

            
            
          }
          );
        }

    },
    apkPrint(){


      //console.log('sendImageToPrint')

      this.printing = true;

      let c = document.getElementById("apkLogoCanvasTm");
      let ctx = c.getContext("2d");
      let img = document.getElementById("apkTicketImageTm");
      
      ctx.drawImage(img, 0, 0,document.getElementById("apkTicketImageTm").offsetWidth,document.getElementById("apkTicketImageTm").offsetHeight);
      let logoData = c.toDataURL('image/png').split(';base64,')[1]


      let ticket =  new TicketData();

    
      ticket.logoData       = logoData;     
      ticket.totalAmount    = this.$store.getters.getPhrase('ticket_total_amount') + this.currency+this.getTotal;
      ticket.numbersAmount  = this.$store.getters.getPhrase('ticket_raffles_amount') + this.selectedNumbers.length;
      ticket.gameDate       = this.$store.getters.getPhrase('ticket_play_date') + this.getPrintTicketDate();
      ticket.terminalData   = this.$store.getters.getPhrase('ticket_terminal_label') + ' 1208-001';
      ticket.qrValue        = this.qrValue;
      ticket.printDate      = this.$store.getters.getPhrase('ticket_print_date') + this.getPrintTicketDate();
      ticket.message        = this.$store.getters.getPhrase('ticket_good_luck');
      ticket.logoWidth      = 150;
      ticket.logoHeight     = 150;
      ticket.fontSize       = 23;

      
      ticket.numbers = [];

      for (let i = 0; i < this.selectedNumbers.length; i++) {

        let raffle = this.selectedNumbers[i];       
        let type = raffle.type;
        let bet = String(this.currency + raffle.bet);
        let date = String(raffle.date).replace(/\s/g, '');
        let num = raffle.number;

        if(raffle.blindRooster){
          type += ' '+this.$store.getters.getPhrase('ticket_type_blind_rooster');
        }
       
       
        if(!raffle.blindRooster){
           type =  type.padEnd(6);
        }else{
           type =  type.padStart(6);
        }
        bet = bet.padStart(8);
        num = num.padStart(4);
        date = date.padStart(7);       
      

        let row = this.getIndex(i)+' '+type+' '+num+' '+date+' '+bet;
        ticket.numbers.push(row);
        
      }

     

      try {

        let ref = this;
        this.$printer.printTicket(ticket,function(){
            //console.log('Print end');
            ref.printDialog = false;    
            ref.printing = false;      
            ref.onClear();
        },function(){
            ref.printing = false;  
        });

        
      } catch (error) {
        this.printing = false;
        alert('Print Error '+error);
      }

    },
     onAceptDates(){
      this.onAceptar();
    },
    onCancelDates(){
      //this.showAlertArrow = true;
      this.selectedDays = [this.days[0]];
      this.defaultDay = !this.defaultDay;
    },
    onCancel(){
        //this.showCancel = false;
        this.currentState = this.RAFFLE_DATE_STATE;
        this.checkRaffleTimes();
    },
  
    onShowDates(){
     // console.log('show dates')
      this.showAlertArrow = false;
     
    },
    resolutionUpdated() {
      var width = Number(window.screen.availWidth);
      var height = Number(window.screen.availHeight);
     // console.log("Resolution Updated " + width + "X" + height);
      if (width < height || width < 1000) {
        this.portrait = true;
        //console.log("Portrait");
      } else {
        this.portrait = false;
       // console.log("Landscape");
      }
    },
    onBet() {
      this.disabledConfirmarBet = true;
      this.acceptDisabled = true;
      this.currentPadTagert = "amountBet";
      this.showBetConfirm = true;
      this.amountBet = "-";
      this.alertText = this.$store.getters.getPhrase('info_enter_notification');
    },
    onReventadoBet() {
      if (this.reventado) {
        this.currentPadTagert = "reventadoAmountBet";
        this.reventadoAmountBet = "-";
      }
    },
    onConfirmarBet() {
      if (this.validateBet()) {
        this.showBetConfirm = false;
        this.currentPadTagert = "currentNumber";
        //this.resetCustomBet = this.previousBet;
        this.disabledConfirmarBet = true;
        this.disabledReventado = false;
        this.validateNumber();
      }
    },
    validateTotal(total, equal) {
      if (equal) {
        if (Number(total) >= this.MAX_BET) {
          this.alertText = this.$store.getters.getPhrase('error_notification_excess_bet') + this.MAX_BET;
          this.acceptDisabled = true;
        } else {
          if (this.selectedNumbers.length < this.MAX_NUMBERS) {
            if (this.currentNumber != "") {
              if (Number(this.currentNumber) > 100) {
                this.acceptDisabled = false;
              }
            }
          } else {
            this.alertText = this.$store.getters.getPhrase('error_notification_excess_numbers');
          }
          //this.setRaffleAlert();
        }
      } else {
        if (Number(total) > this.MAX_BET) {
          this.alertText = this.$store.getters.getPhrase('error_notification_excess_bet') + this.MAX_BET;
          this.acceptDisabled = true;
        } else {
          if (this.selectedNumbers.length < this.MAX_NUMBERS) {
            if (this.currentNumber != "") {
              if (Number(this.currentNumber) > 100) {
                this.acceptDisabled = false;
              }
            }
          }
          //this.setRaffleAlert();
        }
      }
      //console.log('Valid Total '+this.acceptDisabled);
    },
    duplicateBet(newNumber) {
     let exits = false;
       
      for (let e = 0; e < this.selectedDays.length; e++) {


        let testNumber = {
          number:this.currentNumber,
          type:this.currentRaffleType,
          date:this.selectedDays[e].date,
          time:this.selectedDays[e].time
        }

        let time = "";
        if (String(testNumber.time).toLowerCase() === "n") {
          time = "N";
        } else {
          time = "M";
        }
        let day = testNumber.date + " " + time;
        testNumber.date = day;

       //console.log('duplicateBet Test number ')
       //console.log(testNumber.number+' - '+testNumber.type+' - '+testNumber.date)

        
        for (let i = 0; i < this.selectedNumbers.length; i++) {


            //console.log('duplicateBet selectedNumbers ')
            //console.log(this.selectedNumbers[i].number+' - '+this.selectedNumbers[i].type+' - '+this.selectedNumbers[i].date)
            if (
              this.selectedNumbers[i].number == testNumber.number &&
              String(this.selectedNumbers[i].type).toLowerCase() == String(testNumber.type).toLowerCase() &&
              this.selectedNumbers[i].date == testNumber.date
            ) {
              exits = true;
            }
        }
        
      }
     
      return exits;
    },
    onPrint() {
      //console.log("onPrint");
      this.qrSize = document.getElementById('printTicket').offsetWidth * 0.270; 
      this.printDialog = true;
    },
    onDialogAccept() {
      this.onCreateTicket();
     
    },
    sendToQuickPrinter(params) {
      //console.log("sendToQuickPrinter");
      var text = this.createTicket(params);
      var textEncoded = encodeURI(text);
      window.location.href =
        "intent://" +
        textEncoded +
        "#Intent;scheme=quickprinter;package=pe.diegoveloper.printerserverapp;end;";
    },
    createTicket(params) {
      var ticketText = "";
      var space = " ";

      //Logo
      ticketText += "<center><image>../img/nuevosTiemposLogo.fffadb05.png<br>";

      var tableDelimiter = ";;";
      //Numbers headers
      ticketText +=
        "<center>" +
        this.forceLength("Juego", 10) +
        tableDelimiter +
        this.forceLength("Numero", 10) +
        tableDelimiter +
        this.forceLength("Fecha", 10) +
        tableDelimiter +
        this.forceLength("Monto", 10) +
        tableDelimiter +
        this.forceLength("R", 10);
      ("<br>");

      //Numbers
      for (let i = 0; i < params.ticketItems.length; i++) {
        var number = this.forceLength(params.ticketItems[i].number, 10);
        var bet = this.forceLength(params.ticketItems[i].bet, 10);
        var type = this.forceLength(params.ticketItems[i].type, 10);
        var reventadoBet = " ";
        if (params.ticketItems[i].reventadoBet != "") {
          reventadoBet = params.ticketItems[i].reventadoBet;
        }
        reventadoBet = this.forceLength(reventadoBet, 10);
        var blindRooster = params.ticketItems[i].blindRooster;
        var date = this.forceLength(params.ticketItems[i].date, 10);
        var index = this.getIndex(i);
        var game = index + " " + type.substring(0, 3).toUpperCase();
        game = this.forceLength(game, 10);

        //console.log('Game ticket '+game);

        if (blindRooster != "") {
          game += " " + blindRooster;
        }
        ticketText +=
          "<center>" +
          game +
          tableDelimiter +
          number +
          tableDelimiter +
          date +
          tableDelimiter +
          bet +
          tableDelimiter +
          reventadoBet +
          "<br>";
      }

      //Raffles amount
      ticketText +=
        "<left>Cantidad de sorteos:" + params.ticketItems.length + "<br>";
      //ticketText += "<right>" + params.ticketItems.length + "<br>";

      //Total Bet
      ticketText += "<left>Monto Total:" + params.total + "<br>";
      //ticketText += "<right>" + params.total + "<br>";

      //Messaje
      ticketText += "<center><line><br>";
      ticketText += '<center><big>"MUCHA SUERTE"<br>';
      ticketText += "<center><line><br>";

      //PRINT DATE
      ticketText += "<left>Fecha jugada:" + this.getPrintDate() + "<br>";
      //ticketText += "<right>" + this.getPrintDate() + "<br>";

      //BARDCODE
      var codeNumber = 123465892;
      ticketText += "<center><barcode128>" + codeNumber + "<br><cut>";

      return ticketText;
    },
    forceLength(target, len) {
      var out = String(target);
      while (out.length < len) {
        out += " ";
      }
      return out;
    },
    onDialogCancel() {
      this.printDialog = false;
    },
    getPrintDisabledStatus() {
      if (this.selectedNumbers.length > 0) {
        return false;
      } else {
        return true;
      }
    },
    onUpdateBet(index) {     
      this.amountBet = this.betButtons[index];
    },
    onUpdateCustomBet() {
      this.customBet = true;
      this.currentPadTagert = "amountBet";
      this.amountBet = "-";  
      this.lblControlAceptar = this.$store.getters.getPhrase('confirm_label_button');
      this.alertType = 'info';
      this.alertText = this.$store.getters.getPhrase('info_bet_amount');
    },
    onUpdateRaffleType(type) {
      //console.log('onUpdateRaffleType '+type)
      this.currentRaffleType = type;
      this.currentNumber = '';
      this.setRaffleAlert();
    },
    onUpdateRaffleDay(index,on,time) {
      
     let selectedIndex = this.selectedDays.indexOf(this.days[index]); 
     if(on){
        this.currentRaffleTime = time
        this.currentRaffleDay = this.days[index];

        let day = this.days[index];
        day.time = time;       
        if((this.selectedDays.length + this.selectedNumbers.length ) < this.MAX_NUMBERS && selectedIndex === -1){
          this.selectedDays.push(day);
        }else{
          this.alertText = this.$store.getters.getPhrase('error_notification_excess_numbers');
        }
      }else{
         
         this.selectedDays.splice(selectedIndex,1);
      }
   
     /*  for (let e = 0; e < this.selectedDays.length; e++) {
       console.log('Day '+this.selectedDays[e].weekday+' '+this.selectedDays[e].time)
        
      } */
    },
    onUpdateRaffleTime(index,time) {
      let selectedIndex = this.selectedDays.indexOf(this.days[index]);
      this.currentRaffleTime = this.times[index];
      if(selectedIndex > -1){
        this.selectedDays[selectedIndex].time = time;
      }
    /*   for (let e = 0; e < this.selectedDays.length; e++) {
       console.log('Day '+this.selectedDays[e].weekday+' '+this.selectedDays[e].time)
        
      } */
    },
    onUpdateRevetando(reventado) {
      this.reventado = reventado;

      if (!this.reventado) {
        this.reventadoAmountBet = 0;
        this.currentPadTagert = "currentNumber";
        this.alertType = "info";
        this.setRaffleAlert();
      } else {
        this.reventadoAmountBet = "-";
        this.currentPadTagert = "reventadoAmountBet";
        this.alertType = "info";
        this.alertText = "Confirme valor de apuesta para reventado";
        this.acceptDisabled = true;
      }
    },
    onKeyboardUpdate(index) {
     

     /*  if(this.currentState != this.RAFFLE_NUMBER_STATE && !this.customBet){
        this.clearCurrentState(this.RAFFLE_NUMBER_STATE);
        this.currentState = this.RAFFLE_NUMBER_STATE;       
      } */
      switch (this.currentPadTagert) {
        case "currentNumber":
          if (this.valueButtons[index] == "<") {
            if (this.duplicateNumber) {
              this.duplicateNumber = false;
            }
            this.currentNumber = String(this.currentNumber).slice(0, -1);
          } else if (this.valueButtons[index].toLowerCase() == this.$store.getters.getPhrase('delete_label_button').toLowerCase()) {
            this.currentNumber = "";
          } else {
            if (this.duplicateNumber) {
              this.currentNumber = "";
              this.duplicateNumber = false;
            }
            if (this.currentNumber.length < 3) {
              this.currentNumber += this.valueButtons[index];
            }
          }

          if (String(this.currentNumber).length == 0) {
            this.alertType = 'info';
            this.alertText = this.$store.getters.getPhrase('info_init_notification_1');
          }
          if (String(this.currentNumber).length == 1) {
            this.alertType = 'info';
            this.alertText = this.$store.getters.getPhrase('info_init_notification_2');
          }
          if (String(this.currentNumber).length == 2) {
            this.alertType = 'info';
            this.alertText = this.$store.getters.getPhrase('info_init_notification_3');
          }      
          if (String(this.currentNumber).length == 3) {
            this.alertType = 'info';
            this.alertText = this.$store.getters.getPhrase('info_enter_notification');
          }
         
          break;
        case "amountBet":
          if (this.valueButtons[index] == "<") {
            if (String(this.amountBet).length > 1) {
              this.amountBet = String(this.amountBet).slice(0, -1);
            } else {
              this.amountBet = "-";
            }
          } else if (this.valueButtons[index].toLowerCase() == this.$store.getters.getPhrase('delete_label_button').toLowerCase()) {
            this.amountBet = "-";
          } else {
             //console.log('onKeyboardUpdate '+typeof(this.amountBet))
            if (String(this.amountBet).length < 7) {
              this.amountBet = String(this.amountBet).replace("-", "");
              this.amountBet += this.valueButtons[index];
            }
          }
           //console.log('onKeyboardUpdate '+this.amountBet)
          //this.validateBet();

          break;    

        default:
          break;
      }
    },
    onClear() {
      this.selectedNumbers = [];
      
      this.selectedDays = [this.days[0]];
      this.amountBet = 100;
      this.currentPadTagert = 'currentNumber';
      if(!this.checkLimitTime()){
        this.currentRaffleTime = this.times[1]
      }else{
        this.currentRaffleTime = this.times[0]
      }
      this.clearDays = !this.clearDays;
      this.defaultTime = !this.defaultTime;
      this.defaultDay = !this.defaultDay;
      this.defaultBet = !this.defaultBet;
      this.reset = !this.reset;
      this.setRaffleAlert();
      
    },
    onDelete(index) {
      this.selectedNumbers.splice(index, 1);
      this.validateTotal(Number(this.getTotal));
      this.setRaffleAlert();
    },
   
    onAceptar() {

     
      /// console.log('onAceptar after '+this.currentState)
      
       if(this.customBet){
       
          if(this.validateBet()){
            this.customBet = false;
            this.lblControlAceptar = this.$store.getters.getPhrase('enter_label_button');
            this.currentPadTagert = 'currentNumber';
            this.setRaffleAlert();
          }
        }else{
          if(this.validateBet() && this.validateDays() && this.validateNumber()){
            this.insertRaffle();
          }
        }


    },
     validateDays(){
      
      if(this.selectedDays.length > 0){
        return true;
      }else{
        this.alertType = 'info';
        this.alertText = "Debe seleccionar por lo menos un dia para el sorteo";
      }
    },
    insertRaffle(){
     

      for (let i = 0; i < this.selectedDays.length; i++) {
          
          
        
      
        var selectedNumber = {
          number: "",
          type: "",
          bet: "",
          reventadoBet: "",
          raffleDay: "",
          raffleTime: "",
          blindRooster: "",
        };

        if (this.blindRooster) {
          selectedNumber.blindRooster = this.$store.getters.getPhrase('ticket_type_blind_rooster');
        }

       /*  if (this.portrait) {         
          if(String(this.currentRaffleType).toLowerCase() == 'ord/des'){
            this.currentRaffleType = 'O/D';
            selectedNumber.type = this.currentRaffleType;
          }else{
            selectedNumber.type = this.currentRaffleType
            .substring(0, 3)
            .toUpperCase();
          }
          
        } else {
          selectedNumber.type = this.currentRaffleType;
        } */

        let ticketRaffleLabel = '';

        switch (this.currentRaffleType) {
          case 'Orden':
              ticketRaffleLabel = this.$store.getters.getPhrase('order_bet_type_label');
            break;
          case 'Desorden':
              ticketRaffleLabel = this.$store.getters.getPhrase('disorder_bet_type_label');
            break;
          case 'Ord/Des':
              ticketRaffleLabel = this.$store.getters.getPhrase('or_de_bet_type_label');
            break;
          case 'Ultimos':
              ticketRaffleLabel = this.$store.getters.getPhrase('ticket_type_last');
            break;
        
          default:
            break;
        }

        if (this.portrait) {
         if(String(this.currentRaffleType).toLowerCase() == 'ord/des'){
            this.currentRaffleType = 'O/D';
            ticketRaffleLabel = 'O/D';
            selectedNumber.type = ticketRaffleLabel;
          } else{
            selectedNumber.type = ticketRaffleLabel
            .substring(0, 3)
            .toUpperCase();
          }
        } else {
          if(String(this.currentRaffleType).toLowerCase() == 'ord/des'){
            this.currentRaffleType = 'O/D';
            ticketRaffleLabel = 'O/D';
            selectedNumber.type = ticketRaffleLabel;
          }else{
          selectedNumber.type = ticketRaffleLabel;
          }
        }

        console.log('Raffle currentRaffleType '+this.currentRaffleType);
        console.log('Raffle type '+selectedNumber.type);

        selectedNumber.bet = this.amountBet;

        if (
          this.reventadoAmountBet != '-' &&
          String(this.currentRaffleType).toLowerCase() == "exacto"
        ) {
          selectedNumber.reventadoBet = this.reventadoAmountBet;
        } else {
          selectedNumber.reventadoBet = "";
        }

        selectedNumber.raffleDay =
          this.selectedDays[i].weekday + " " + this.selectedDays[i].date;
        selectedNumber.raffleTime = this.selectedDays[i].time;
        var time = "";
        if (String(this.selectedDays[i].time).toLowerCase() == "n") {
          time = "N";
        } else {
          time = "M";
        }
        if(!this.checkLimitTime() && this.selectedDays[i].weekday.toLowerCase() === this.$store.getters.getPhrase('today_label').toLowerCase() ){
          time = 'N';
        }
        var day = this.selectedDays[i].date + " " + time;
        selectedNumber.date = day;
       
        selectedNumber.number = this.currentNumber;

        this.selectedNumbers.push(selectedNumber);

      
      }
      
     
      this.currentNumber = '';
      this.blindRooster =  false;
      this.setRaffleAlert();
    
      

    },
    resetValues(resetDefault = false) {
      this.reventadoAmountBet = 0;
      this.currentNumber = "";
      this.reventado = false;
      this.blindRooster = false;
      this.reset = !this.reset;
      this.resetDefault = resetDefault;
      this.currentPadTagert = "currentNumber";
      this.acceptDisabled = true;

      if (this.resetDefault) {
        var now = new Date();
        var hours = now.getHours();

        if (hours > 12) {
          this.currentRaffleTime = this.times[1];
        } else {
          this.currentRaffleTime = this.times[0];
        }
        this.currentRaffleDay = this.days[0];
        this.amountBet = this.betButtons[0];
      }
      this.validateNumber();
      this.validateTotal(Number(this.getTotal), true);
    },
    onBlindRooster() {
      this.currentNumber = String(this.getRandomInRange(100, 1000));
      switch (String(this.currentRaffleType).toLowerCase()) {
       
        case "desorden":   
        case "ord/des":
          while (this.hasRepeatingDigits(Number(this.currentNumber))) {
             this.currentNumber = String(this.getRandomInRange(100, 1000));
          }
       
        default:
          break;
      }
      this.blindRooster = true;
      this.alertType = 'info';
      this.alertText = this.$store.getters.getPhrase('info_enter_notification');
      this.validateNumber();
      //this.validateTotal(Number(this.getTotal), true);
    },
    setRaffleAlert() {
      /* switch (String(this.currentRaffleType).toLowerCase()) {
        case "primero":
          this.alertType = "info";
          this.alertText = "Ingrese un número de 1 dígito entre 0 y 9";
          break;
        case "terminacion":
          this.alertType = "info";
          this.alertText = "Ingrese un número de 1 dígito entre 0 y 9";
          break;
        case "exacto":
          this.alertType = "info";
          this.alertText = "Ingrese un número de hasta 2 dígitos";
          break;
        case "reversible":
          this.alertType = "info";
          this.alertText = "Ingrese un número de hasta 2 dígitos diferentes";
          break;

        default:
          break;
      } */
      this.alertType = 'info';
      this.alertText = this.$store.getters.getPhrase('info_init_notification_1');
    },   
    validateNumber() {
      let valid = true;
      let duplicateDigit = false;
      switch (String(this.currentRaffleType).toLowerCase()) {
        case "orden":
          //console.log('validateNumber '+Number(this.currentNumber))
          if (Number(this.currentNumber) < 100) {
           
            valid = false;
          }
          break;
        case "ultimos":
         
          if (Number(this.currentNumber) < 100) {
            
            valid = false;
          }

          break;
        case "desorden":
          
          if (Number(this.currentNumber) < 100) {
          
            valid = false;
          }else if(this.hasRepeatingDigits(Number(this.currentNumber))){           
            duplicateDigit = true;
          }

          break;
        case "ord/des":
          
          if (Number(this.currentNumber) < 100) {
           
            valid = false;

          }else if(this.hasRepeatingDigits(Number(this.currentNumber))){

            duplicateDigit = true;
          }

        default:
          break;
      }
      if(duplicateDigit){
        this.alertType = "error";
        this.alertText = this.$store.getters.getPhrase('error_notification_equals_numbers');
        valid = false;
      }
      if (this.currentNumber == "") {       
        this.setRaffleAlert();
        valid = false;
      }else if(this.duplicateBet()){
        this.alertType = "error";
        this.alertText = this.$store.getters.getPhrase('error_notification_duplicate_bet');
        valid = false;
      
      }else if((this.selectedNumbers.length === this.MAX_NUMBERS)){
        this.alertType = "error";
        this.alertText = this.$store.getters.getPhrase('error_notification_excess_numbers');
        valid = false;
      }

      return valid;

    },
    hasRepeatingDigits(n) {
      return (/([0-9]).*?\1/).test(n)
    },
    validateBet() {
       let valid = true;

      if(this.amountBet == 0){
          this.alertType = "error";
          this.alertText = this.$store.getters.getPhrase('error_notification_bet_zero');
          valid = false;
      }else if (this.amountBet == "-") {
          this.alertType = "info";
          this.alertText = this.$store.getters.getPhrase('info_bet_amount');
          valid = false;
      }else if (Number(this.amountBet) < this.MIN_BET) {
          this.alertType = "error";
          this.alertText = this.$store.getters.getPhrase('info_bet_amount_minum') + this.MIN_BET;
          valid = false;
      }else if(Number(this.getTotal) + Number(this.amountBet) > this.MAX_BET){
          this.alertType = "error";
          this.alertText = this.$store.getters.getPhrase('info_bet_amount_maximum') + this.MAX_BET;
          valid = false;
      }
     
     
      return valid;
    },
    setDates() {
     var weekDays = [
        this.$store.getters.getPhrase('day_6'),
        this.$store.getters.getPhrase('day_0'),
        this.$store.getters.getPhrase('day_1'),
        this.$store.getters.getPhrase('day_2'),
        this.$store.getters.getPhrase('day_3'),
        this.$store.getters.getPhrase('day_4'),
        this.$store.getters.getPhrase('day_5')
         ];
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth()).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      this.days = [
        {
          weekday: this.$store.getters.getPhrase('today_label'),
          date: dd + "/" + String(Number(mm)+1),
        },
      ];

      for (let i = 0; i < 8; i++) {
        //new Date(1995,11,17);
        var weekDay = new Date(yyyy, mm, Number(dd) + i + 1);
        var date =
          String(weekDay.getDate()).padStart(2, "0") +
          "/" +
          String(weekDay.getMonth() + 1).padStart(2, "0"); //January is 0!

        var day = {
          weekday: weekDays[weekDay.getDay()],
          date: date,
          time:'-'
        };

        this.days.push(day);
      }
    },
    getRandomInRange(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    getPrintDate() {
       var weekDays = [
        this.$store.getters.getPhrase('day_6'),
        this.$store.getters.getPhrase('day_0'),
        this.$store.getters.getPhrase('day_1'),
        this.$store.getters.getPhrase('day_2'),
        this.$store.getters.getPhrase('day_3'),
        this.$store.getters.getPhrase('day_4'),
        this.$store.getters.getPhrase('day_5')
         ];
       var months = [
         this.$store.getters.getPhrase('month_0'),
        this.$store.getters.getPhrase('month_1'),
        this.$store.getters.getPhrase('month_2'),
        this.$store.getters.getPhrase('month_3'),
        this.$store.getters.getPhrase('month_4'),
        this.$store.getters.getPhrase('month_5'),
        this.$store.getters.getPhrase('month_6'),
        this.$store.getters.getPhrase('month_7'),
        this.$store.getters.getPhrase('month_8'),
        this.$store.getters.getPhrase('month_9'),
        this.$store.getters.getPhrase('month_10'),
        this.$store.getters.getPhrase('month_11'),
      ];
      var today = new Date();
      var dd = today.getDate();
      var mm = months[today.getMonth()+1];
      var yyyy = today.getFullYear();
      var weeDay = weekDays[today.getDay()];
      var hours = today.getHours();
      var minutes = this.getZeroPad(today.getMinutes());

      var printDate =
        weeDay +
        " " +
        dd +
        " " +
        mm +
        " " +
        yyyy +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        this.getHourTime(hours);

      return printDate;
    },
    getPrintTicketDate(){
     
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth()+1;
      var yyyy = today.getFullYear();
      var hours = today.getHours();
      var minutes = this.getZeroPad(today.getMinutes());

      var printDate =
        dd +
        "/" +
        mm +
        "/" +
        yyyy +
        "/" +
        hours +
        ":" +
        minutes;
        

      return printDate;
    },
    getHourTime(h) {
      return h >= 12 ? "PM" : "AM";
    },
    getZeroPad(n) {
      return (parseInt(n, 10) >= 10 ? "" : "0") + n;
    },
    getIndex(index) {
      var alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return alphabet[index];
    },
  },
};
</script>