<template>
  <div class="control-panel-portrait">
    <div class="control-row-notifacitons">
      <div class="notifications-alert" :class="getAlertTitleClass()">
        <v-icon v-if="alertType === 'info'" class="alert-icon-info"
          >mdi-exclamation-thick</v-icon
        >
        <v-icon v-if="alertType === 'error'" class="alert-icon-error"
          >mdi-alert</v-icon
        >
        <p class="alert-text">{{ alertText }}</p>
        
         <v-icon v-if="showAlertArrow"  class="alert-arrow"
          >mdi-arrow-down</v-icon
        >
      </div>
    </div>
    <div class="control-row-buttons">
      <v-navigation-drawer
        v-model="drawer"
        right
        absolute
        temporary
        width="85%"
        color="#0077AD"
      >
        <div class="controls-drawer-container">
          
          <div class="first-row">
            
            <div class="raffles-options-container">    
               <raffle-date 
              
              v-for="(day, index) of days" :key="index"
              :default="defaultDay"
              :date="day"
              :index="index"
              :disabled="disabledDateControls"
              :matutinoTime="matutinoTime"
              v-on:on-date-change="onDateChange"
              v-on:on-time-change="onDateTimeChange"
              />    
            <!--<div class="raffle-options">
             
              <v-btn
                v-for="(day, index) of days"
                :key="index"
                :id="'btnRaffleOption-' + index"
                class="raffle-option-button"
                @click="onRaffleButtonClick(index,$event)"
                :disabled="disabledDateControls"
                elevation="2"
                
              >
                <span v-if="day.weekday != 'HOY'">{{
                  day.weekday + " " + day.date
                }}</span>
                <span v-else>{{ day.weekday }}</span>
              
              </v-btn> 
            </div>-->
            </div>
            <div class="time-options-container">
                <p class="time-title"><span>{{getPhrase('raffle_morning_label')}}: {{ matutinoTime }}</span><span>{{getPhrase('raffle_night_label')}}: {{ nocturnoTime }}</span></p>              
            </div>
         <!--    <v-btn
              v-for="(day, index) of days"
              :key="index"
              :id="'btnRaffleOption-' + index"
              class="raffle-option-button"
              @click.stop="onRaffleButtonClick(index,$event)"
              elevation="2"
            >
              <span v-if="day.weekday != 'HOY'">{{
                day.weekday + " " + day.date
              }}</span>
              <span v-else>{{ day.weekday }}</span>
            </v-btn> -->
          </div>
         <!--  <div class="second-row">
            <v-btn
              v-for="(time, index) of times"
              :key="index"
              class="time-option-button"
              :id="'btnRaffleTimeOption-' + index"
              @click="onRaffleTimeButtonClick(index)"
              elevation="2"
              :disabled="getDisabledTimeStatus(time)"
            >
              {{ time }}
            </v-btn>
          </div> -->
          <div class="third-row">
            <v-btn @click.stop="onCancelDates" class="cancel-button">{{getPhrase('cancel_label_button')}}</v-btn>
            <v-btn @click.stop="onAceptDates" class="acept-button">{{getPhrase('accept_label_button')}}</v-btn>
           
          </div>
        </div>
      </v-navigation-drawer>
     
        <div class="control-row-1">
          <div class="row-1-col-left">
             <v-btn
              id="btnExacto"
              @click="onExactoClick"
              class="display-button"
              elevation="2"
            >
              {{ getPhrase('exact_bet_type_label') }}
            </v-btn>
             <v-btn
              id="btnReversible"
              @click="onReversibleClick"
              class="display-button"
              elevation="2"
            >
              {{ getPhrase('reversible_bet_type_label') }}
            </v-btn>
            <v-btn
              id="btnPrimero"
              @click="onPrimeroClick"
              class="display-button"
              elevation="2"
            >
              {{ getPhrase('first_bet_type_label') }}
            </v-btn>
            <v-btn
              id="btnTerminacion"
              @click="onTerminacionClick"
              class="display-button button-terminacion"
              elevation="2"
            >
              {{ getPhrase('termination_bet_type_label') }}
            </v-btn>
           
           
            <v-btn
              id="btnGalloTapado"
              @click="onBlindRooster"
              class="control-rooster-button"
              :disabled="disabledControls"
              elevation="2"
            >
              {{ getPhrase('blind_rooster') }}
            </v-btn>
            <v-btn
              id="chip-bet-0"
              @click="onButtonBetClick(0)"
              class="display-button chip-bet"
               :disabled="disabledBetAmounts"
              elevation="2"
            >
              {{ 100 }}
            </v-btn>
           <!--  <v-btn
              id="chip-bet-1"
              @click="onButtonBetClick(1)"
              class="display-button chip-bet"
               :disabled="disabledBetAmounts"
              elevation="2"
            >
             {{200}}
            </v-btn> -->
            <v-btn
              class="display-button chip-bet"
              @click="onButtonBetClick('d')"
              :id="'chip-bet-d'"
              :disabled="disabledBetAmounts"
            >
              <v-icon class="bet-dots" color="#FFF">mdi-dots-horizontal</v-icon>
            </v-btn>
          </div>
          <div  class="row-1-col-right"  >
           <!--  <div class="row-1">
              <p class="control-col-right-text">{{ getCurrentRaffleDay() }}</p>
              <p class="control-col-right-text">{{ getCurrentRaffleTime() }}</p>
            </div> -->
            <v-btn @click="onShowControls" class="dates-button" :disabled="disabledDateControls">
              <v-icon class="control-col-right-icon">mdi-calendar-clock-outline</v-icon>
              <v-icon class="control-col-right-icon">mdi-arrow-left</v-icon>
            </v-btn>
           
            <!-- <div class="row-2">
              <v-icon class="control-col-right-icon">mdi-calendar-clock</v-icon>
              <v-icon class="control-col-right-icon">mdi-arrow-left</v-icon>
              
            </div>      -->       
          </div>
        </div>
        <div class="control-row-2">
          <div class="buttons-col-left">
            <v-btn
              v-for="(btn, index) of valueButtons"
              :key="index"
              :class="getButtonClass(btn)"
              @click="onButtonValueClick(index)"
              :disabled="disabledValueControls"            
              elevation="2"
            >
              {{ btn }}
            </v-btn>
          </div>

          <div class="buttons-col-right">
             <button v-if="showCancel"
              class="lot-button control-cancel-button"         
              @click="onCancelClick"
              elevation="2"
            >
              <v-icon class="control-button-cancel-icon">mdi-close-circle-outline</v-icon>
              {{ getPhrase('cancel_label_button') }}
              
            </button>
            <button v-else
              class="lot-button control-print-button"
              :disabled="disabledPrint"
              @click="onPrintClick"
              elevation="2"
            >
              <v-icon class="control-button-print-icon">mdi-printer</v-icon>
              {{ getPhrase('print_label_button') }}
              {{ getPhrase('ticket_label_button') }}
            </button>
            <button
              :disabled="acceptDisabled"
              @click="onAceptarClick"
              class="lot-button control-button-aceptar"
            >
              <v-icon class="control-button-aceptar-icon"
                >mdi-keyboard-return</v-icon
              >
              {{ lblAceptar }}
            </button>
          </div>
        </div>
     
      
    </div>
  </div>
</template>

<script>

import RaffleDate from "../shared/RaffleDate.vue";
import { mapGetters } from "vuex";

export default {
  name: "ControlPanel",
  components: {
    RaffleDate
  },
  data() {
    return {
      lblDisplayNumber: "NUMERO SELECCIONADO",
      lblDisplayBet: "APUESTA",
      lblCancelar: "CANCELAR",
      lblBetDialog: "MONTO DE APUESTA",     
      lblAceptarDays: "Aceptar",
      lblPrimero: "Primero",
      lblTerminacion: "Terminación",
      lblExacto: "Exacto",
      lblReversible: "Reversible",
      lblRaffles: "Sorteos",
      lblTimes: "Horario",
      lblReventados: "Reventados",
      lblPrint: "Imprimir",
      lblTicket: "Ticket",
      currentPadTagert: "currentNumber",
      lblRooster: "Gallo tapado",
      lblBet: "Monto en",     
      drawer: false,
      defaultDay: false,
    };
  },

  mounted() {
    this.resetValues();
    //this.checkRaffleTime();
    this.toggleActiveRaffleButton("btnExacto");
    this.updateRaffle("Exacto");
  },
  props: [
    "days",
    "times",
    "betButtons",
    "valueButtons",
    "currency",
    "disabledPrint",
    "acceptDisabled",
    "customBetDisabled",
    "lblAceptar",
    "reset",
    "matutinoTime",
    "nocturnoTime",
    "resetCustomBet",
    "currentRaffleDay",
    "currentRaffleTime",
    "alertType",
    "alertText",
    "disabledBetAmounts",    
    "disabledControls",    
    "disabledValueControls",    
    "disabledDateControls",   
    "setDefaultBet", 
    "setDefaultDay", 
    "setDefaultTime", 
    "showCancel",
    "showAlertArrow",
    "deactivateDefaultBets",
    "disabledMatutino",   
    "clearDays"
  ],
  watch: {
    reset(val) {
      this.resetValues();
    },
    resetCustomBet(val) {
      this.resetBet(val);
    },
    clearDays() {
      this.toggleActiveRaffle("btnRaffleOption-0");
    },
    setDefaultBet(val){    
      
       setTimeout(() => {
          this.toggleActiveBetChip("chip-bet-0");
       }, 50);
     
    },
    deactivateDefaultBets(val){     
      
       setTimeout(() => {
          this.toggleActiveBetChip(-1);
       }, 50);
     
    },
    setDefaultDay(val){     
       setTimeout(() => {
          this.defaultDay = !this.defaultDay;
       }, 50);
     
    },
    setDefaultTime(val){     
     
      setTimeout(() => {
        if(this.disabledMatutino){
          this.toggleActiveRaffleTime("btnRaffleTimeOption-1");
        }else{
          this.toggleActiveRaffleTime("btnRaffleTimeOption-0");
        }
          
       }, 50);
    }
  },

  filters: {
    addCurrency(val, currency) {
      if (val == "0" || val == "") {
        return "";
      } else {
        return val + " " + currency;
      }
    },
  },
  computed:{   
     ...mapGetters([
      'getPhrase',     
      // ...
    ])
  },

  methods: {
    onDateChange(on,index,time){
      //console.log('onDateChange ',index+' '+on+' '+time);
      this.$emit('on-update-raffle-day',index,on,time);
    },
    onDateTimeChange(time,index){
      this.$emit('on-update-raffle-time',index,time);
    },

    onAceptDates(){
      this.$emit('acept-dates');
      this.closeDrawer();
    },
    onCancelDates(){
      this.$emit('cancel-dates');
      this.toggleActiveRaffle("btnRaffleOption-0");
      this.closeDrawer();
    },
    getCurrentRaffleDay() {
      return String(this.currentRaffleDay.date).split("/")[0];
    },
    getCurrentRaffleTime() {
      return String(String(this.currentRaffleTime).split("")[0]).toUpperCase();
    },
    getAlertTitleClass() {
      if (this.alertType == "error") {
        return "notifications-alert notification-alert-error";
      } else {
        return "notifications-alert  notification-alert-info";
      }
    },
    onShowControls() {
      this.drawer = true;
      this.$emit("show-dates");
    },
    checkRaffleTime() {
      var currentDate = new Date();
      var hours = currentDate.getHours();
      var minutes = currentDate.getMinutes();
      var seconds = currentDate.getSeconds();
      var nowTime = hours + ":" + minutes + ":" + seconds;
      var limitTime = this.matutinoTime + ":00";

      var nowDate = new Date(currentDate.getTime());
      nowDate.setHours(nowTime.split(":")[0]);
      nowDate.setMinutes(nowTime.split(":")[1]);
      nowDate.setSeconds(nowTime.split(":")[2]);

      var limitDate = new Date(currentDate.getTime());
      limitDate.setHours(limitTime.split(":")[0]);
      limitDate.setMinutes(limitTime.split(":")[1]);
      limitDate.setSeconds(limitTime.split(":")[2]);

      if (
        nowDate > limitDate &&
        this.currentRaffleDay.weekday == this.days[0].weekday
      ) {
       
        setTimeout(() => {
          this.onRaffleTimeButtonClick(1);
        }, 50);
      } else {
       
        setTimeout(() => {
          this.onRaffleTimeButtonClick(0);
        }, 50);
      }
    },
    getDisabledTimeStatus(time) {
      if (String(time).toLowerCase() == "mediodia" && this.disabledMatutino) {
        return true;
      } else {
        return false;
      }
    },
    resetBet(val) {
     /*  var betIndex = this.betButtons.indexOf(String(val));
      if (betIndex > -1) {
        this.toggleActiveBetChip("chip-bet-" + betIndex);
      } else {
        this.toggleActiveBetChip("chip-bet-0");
      } */
    },
    resetValues() {
     // console.log("Control Panel Reset");
     // this.toggleActiveBetChip("chip-bet-0");
    //  this.toggleActiveRaffle("btnRaffleOption-0");
      //this.checkRaffleTime();
    },
    createRipple(event) {
      const button = event.currentTarget;
      const circle = document.createElement("span");

      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
      circle.style.width = circle.style.height = `${diameter}px`;
      /*  circle.style.left = `${event.clientX - (button.offsetLeft + radius)}px`;
      circle.style.top = `${event.clientY - (button.offsetTop + radius)}px`; */

      circle.style.left = `${button.clientWidth * 0.5 - diameter * 0.5}px`;
      circle.style.top = `${button.clientHeight * 0.5 - diameter * 0.5}px`;

      circle.classList.add("ripple");

      const ripple = button.getElementsByClassName("ripple")[0];

      if (ripple) {
        ripple.remove();
      }
      button.appendChild(circle);
    },
    onCancelClick(event) {
      this.createRipple(event);
      this.$emit("cancel");
    },
    onPrintClick(event) {
      this.createRipple(event);
      this.$emit("print");
    },
    getButtonClass(label) {
      if (String(label).toLowerCase() == this.$store.getters.getPhrase('delete_label_button').toLowerCase()) {
        return "control-delete-button";
      } else if (String(label).toLowerCase() == "<") {
        return "control-delete-back-button";
      } else {
        return "control-value-button";
      }
    },
    onButtonBetClick(index) {
     
      if (index != "d") {
        this.toggleActiveBetChip("chip-bet-" + index);
        this.$emit("update-bet", index);
      } else {
        this.toggleActiveBetChip("chip-bet-d")
        this.$emit("update-custom-bet");
      }
    },
    onButtonValueClick(index) {
      this.$emit("keyboard-update", index);
    },
    onRaffleButtonClick(index,event) {
      //this.closeDrawer();
      //this.toggleActiveRaffle("btnRaffleOption-" + index);
      event.currentTarget.classList.toggle("raffle-option-active");  
      this.$emit("on-update-raffle-day", index);
    },
    onRaffleTimeButtonClick(index) {
      //this.closeDrawer();
      this.toggleActiveRaffleTime("btnRaffleTimeOption-" + index);
      this.$emit("on-update-raffle-time", index);
    },
    closeDrawer() {
      var ref = this;
      setTimeout(() => {
        ref.drawer = false;
      }, 300);
    },
    onAceptarClick(event) {
      this.createRipple(event);
      this.$emit("on-aceptar-tap");
    },
    onBlindRooster() {
      this.$emit("on-blind-rooster-tap");
    },
    onPrimeroClick() {
      this.toggleActiveRaffleButton("btnPrimero");
      this.updateRaffle("Primero");
    },
    onTerminacionClick() {
      this.toggleActiveRaffleButton("btnTerminacion");
      this.updateRaffle("Terminación");
    },
    onExactoClick() {
      this.toggleActiveRaffleButton("btnExacto");
      this.updateRaffle("Exacto");
    },
    onReversibleClick() {
      this.toggleActiveRaffleButton("btnReversible");
      this.updateRaffle("Reversible");
    },
    updateRaffle(raffle) {
      this.$emit("update-raffle-type", raffle);
    },
    toggleActiveRaffleButton(id) {
      var buttons = document.getElementsByClassName("display-button");
      //console.log("toggleActiveRaffleButton " + buttons.length);
      for (let i = 0; i < buttons.length; i++) {
        //console.log("id " + buttons[i].id);
        if (buttons[i].id == id) {
          //console.log('Agregando clase a  '+buttons[i].id)
          document.getElementById(id).classList.add("display-button-active");
        } else {
          buttons[i].classList.remove("display-button-active");
        }
      }
    },

    toggleActiveBetChip(id) {
    
      var chips = document.getElementsByClassName("chip-bet");
     
      for (let i = 0; i < chips.length; i++) {

        if(id == -1){
          chips[i].classList.remove("bet-chip-active");
        }else{
          if (chips[i].id == id) {
            
            document.getElementById(id).classList.add("bet-chip-active");
          } else {
            chips[i].classList.remove("bet-chip-active");
          }
        }
        
        
      }
    },
    toggleActiveRaffle(id) {
      var buttons = document.getElementsByClassName("raffle-option-button");

      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id == id) {
          buttons[i].classList.add("raffle-option-active");
        } else {
          buttons[i].classList.remove("raffle-option-active");
        }
      }
    },
    toggleActiveRaffleTime(id) {
      var buttons = document.getElementsByClassName("time-option-button");

      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id == id) {
          buttons[i].classList.add("time-option-active");
        } else {
          buttons[i].classList.remove("time-option-active");
        }
      }
    },
  },
};
</script>