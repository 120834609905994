<template>
  <div class="control-panel-tm">
    <div class="control-back"></div>
    <div class="control-row-1">
      <p class="bet-label">{{ getPhrase('bet_buttons_label') | addCurrency(this.currency) }}</p>
      <div class="control-bet">
        <v-chip
          v-for="(btn, index) of betButtons"
          :key="index"
          :id="'chip-bet-' + index"
          @click="onButtonBetClick(index)"
          :disabled="disabledBetAmounts"
          elevation="2"
          class="bet-chip"
        >
          {{ btn }}
        </v-chip>
        <v-chip
          class="bet-chip"
          @click="onButtonBetClick('d')"
          :disabled="disabledBetAmounts"
          :id="'chip-bet-d'"
        >
          <v-icon color="#FFF">mdi-dots-horizontal</v-icon>
        </v-chip>
      </div>
    </div>
    <div class="control-row-2">
      <div class="control-col-left">
        <v-btn
          class="control-rooster-button"
          @click="onBlindRooster"
          elevation="2"
          :disabled="disabledControls"
        >
          {{ getPhrase('blind_rooster') }}
        </v-btn>
        <div class="raffles-options-container">
          <p class="raffle-title">{{ getPhrase('raffles_label') }}</p>
           <div class="raffle-options">
            <raffle-date 
             
             v-for="(day, index) of days" :key="index"
             :default="defaultDay"
             :date="day"
             :index="index"
             :disabled="disabledDateControls"
             :matutinoTime="matutinoTime"
              v-on:on-date-change="onDateChange"
              v-on:on-time-change="onDateTimeChange"
             />         
          </div>
            <div class="time-options-container">
             <!-- <p class="time-title"> --><span>{{getPhrase('raffle_morning_label')}}: {{ matutinoTime }}</span><span>{{ getPhrase('raffle_night_label')}}: {{ nocturnoTime }}</span><!-- </p>    -->           
        </div>   
        </div>
       
      </div>
      <div class="control-col-middle">
        <v-btn
          v-for="(btn, index) of valueButtons"
          :key="index"
          :class="getButtonClass(btn)"
          @click="onButtonValueClick(index)"
          :disabled="disabledValueControls"          
          elevation="2"
        >
          {{ btn }}
        </v-btn>
      </div>
      <div class="control-col-right">
        
        <button 
          class="lot-button control-print-button"
          :disabled="disabledPrint"
          @click="onPrintClick"
          elevation="2"
        >
          <v-icon class="control-button-print-icon">mdi-printer</v-icon>
          {{ getPhrase('print_label_button') }}
          {{ getPhrase('ticket_label_button') }}
        </button>
        <button
          :disabled="acceptDisabled"
          @click="onAceptarClick"
          class="lot-button control-button-aceptar"
        >
          <v-icon class="control-button-aceptar-icon"
            >mdi-keyboard-return</v-icon
          >
          {{ lblAceptar }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RaffleDate from "../shared/RaffleDate.vue";
import { mapGetters } from "vuex";
export default {
  name: "ControlPanel",
    components: {
    RaffleDate
  },
  data() {
    return {
      lblDisplayNumber: "NUMERO SELECCIONADO",
      lblDisplayBet: "APUESTA",
      lblCancelar: "CANCELAR",
      lblBetDialog: "MONTO DE APUESTA",     
      lblPrimero: "Primero",
      lblTerminacion: "Terminación",
      lblExacto: "Exacto",
      lblReversible: "Reversible",
      lblRaffles: "Sorteos",
      lblTimes: "Horario",
      lblReventados: "Reventados",
      lblPrint: "Imprimir",
      lblTicket: "Ticket",
      currentPadTagert: "currentNumber",
      lblRooster: "Gallo tapado",
      lblBet: "Monto en",
       defaultDay: false,
     
    };
  },

  mounted() {
    this.resetValues();
    //this.checkRaffleTime();
  },
  props: [
    "days",
    "times",
    "betButtons",
    "valueButtons",
    "currency",
    "disabledPrint",
    "acceptDisabled",
    "customBetDisabled",
    "reset",
    "lblAceptar",
    "matutinoTime",
    "nocturnoTime",
    "resetCustomBet",
    "currentRaffleDay",    
    "disabledBetAmounts",    
    "disabledControls",    
    "disabledValueControls",    
    "disabledDateControls",   
    "setDefaultBet", 
    "setDefaultDay", 
    "setDefaultTime", 
    "showCancel",
    "deactivateDefaultBets",
    "disabledMatutino"
  ],
  watch: {
    reset(val) {
      this.resetValues();
    },
    resetCustomBet(val) {
      this.resetBet(val);
    },
    currentRaffleDay(){     
      //this.();
    },
    setDefaultBet(val){     
      //console.log('setDefaultBet')
       setTimeout(() => {
          this.toggleActiveBetChip("chip-bet-0");
       }, 50);
     
    },
    deactivateDefaultBets(val){     
      
       setTimeout(() => {
          this.toggleActiveBetChip(-1);
       }, 50);
     
    },
    setDefaultDay(val){     
       setTimeout(() => {
          this.defaultDay = !this.defaultDay;
       }, 50);
     
    },
    setDefaultTime(val){     
     
      setTimeout(() => {
         if(this.disabledMatutino){
          this.toggleActiveRaffleTime("btnRaffleTimeOption-1");
        }else{
          this.toggleActiveRaffleTime("btnRaffleTimeOption-0");
        }
       }, 50);
    }
  },

  filters: {
    addCurrency(val, currency) {
      if (val == "0" || val == "") {
        return "";
      } else {
        return val + " " + currency;
      }
    },
  },
  computed:{   
     ...mapGetters([
      'getPhrase',     
      // ...
    ])
  },

  methods: {
    onDateChange(on,index,time){
      //console.log('onDateChange ',index+' '+on+' '+time);
      this.$emit('on-update-raffle-day',index,on,time);
   },
   onDateTimeChange(time,index){
     this.$emit('on-update-raffle-time',index,time);
   },
    checkRaffleTime() {
      var currentDate = new Date();      
      var hours = currentDate.getHours();
      var minutes = currentDate.getMinutes();
      var seconds = currentDate.getSeconds();
      var nowTime = hours+':'+minutes+':'+seconds;
      var limitTime = this.matutinoTime+":00";    

      var nowDate = new Date(currentDate.getTime());
      nowDate.setHours(nowTime.split(":")[0]);
      nowDate.setMinutes(nowTime.split(":")[1]);
      nowDate.setSeconds(nowTime.split(":")[2]);

      var limitDate = new Date(currentDate.getTime());
      limitDate.setHours(limitTime.split(":")[0]);
      limitDate.setMinutes(limitTime.split(":")[1]);
      limitDate.setSeconds(limitTime.split(":")[2]);  
     
   
      if (nowDate > limitDate && this.currentRaffleDay.weekday == this.days[0].weekday) {
        this.disabledMatutino = true;
       
         setTimeout(() => {
            this.onRaffleTimeButtonClick(1);
        }, 50); 
       
      } else {
        this.disabledMatutino = false;
        setTimeout(() => {
            this.onRaffleTimeButtonClick(0);
        }, 50);        
       
      }
    },
    getDisabledTimeStatus(time) {

      if(this.disabledDateControls){
        return true;
      }
      if (String(time).toLowerCase() == "matutino" && this.disabledMatutino) {
        return true;
      } else {
        return false;
      }
    },
    resetBet(val) {
      var betIndex = this.betButtons.indexOf(String(val));
      if (betIndex > -1) {
        this.toggleActiveBetChip("chip-bet-" + betIndex);
      } else {
        this.toggleActiveBetChip("chip-bet-0");
      }
    },
    resetValues() {
      //console.log("Control Panel Reset");
     /*  this.toggleActiveBetChip("chip-bet-0");
      this.toggleActiveRaffle("btnRaffleOption-0"); */
      //this.checkRaffleTime();

    },
    createRipple(event) {
      const button = event.currentTarget;
      const circle = document.createElement("span");

      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
      circle.style.width = circle.style.height = `${diameter}px`;
      /*  circle.style.left = `${event.clientX - (button.offsetLeft + radius)}px`;
      circle.style.top = `${event.clientY - (button.offsetTop + radius)}px`; */

      circle.style.left = `${button.clientWidth * 0.5 - diameter * 0.5}px`;
      circle.style.top = `${button.clientHeight * 0.5 - diameter * 0.5}px`;

      circle.classList.add("ripple");

      const ripple = button.getElementsByClassName("ripple")[0];

      if (ripple) {
        ripple.remove();
      }
      button.appendChild(circle);
    },
    onPrintClick(event) {
      this.createRipple(event);
      this.$emit("print");
    },
    getButtonClass(label) {
      if (String(label).toLowerCase() == this.$store.getters.getPhrase('delete_label_button').toLowerCase()) {
        return "control-delete-button";
      } else if (String(label).toLowerCase() == "<") {
        return "control-delete-back-button";
      } else {
        return "control-value-button";
      }
    },
    onButtonBetClick(index) {
      this.toggleActiveBetChip("chip-bet-" + index);
      if (index != "d") {
        this.$emit("update-bet", index);
      } else {
        this.$emit("update-custom-bet");
      }
    },
    onButtonValueClick(index) {
      this.$emit("keyboard-update", index);
    },
    onRaffleButtonClick(index,event) {
      event.currentTarget.classList.toggle("raffle-option-active");    
      this.$emit("on-update-raffle-day", index);
     
    },
    onRaffleTimeButtonClick(index) {
     
      this.toggleActiveRaffleTime("btnRaffleTimeOption-" + index);
      this.$emit("on-update-raffle-time", index);
     
    },

    onAceptarClick(event) {
      this.createRipple(event);
      this.$emit("on-aceptar-tap");
    },
    onBlindRooster() {
      this.$emit("on-blind-rooster-tap");
    },

    toggleActiveBetChip(id) {
      var chips = document.getElementsByClassName("bet-chip");
      for (let i = 0; i < chips.length; i++) {
         if(id == -1){
          chips[i].classList.remove("bet-chip-active");
        }else{
          if (chips[i].id == id) {
            
            document.getElementById(id).classList.add("bet-chip-active");
          } else {
            chips[i].classList.remove("bet-chip-active");
          }
        }
      }
    },
    toggleActiveRaffle(id) {
      var buttons = document.getElementsByClassName("raffle-option-button");

      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id == id) {
          buttons[i].classList.add("raffle-option-active");
        } else {
          buttons[i].classList.remove("raffle-option-active");
        }
      }
    },
    toggleActiveRaffleTime(id) {
      var buttons = document.getElementsByClassName("time-option-button");
     
      for (let i = 0; i < buttons.length; i++) {
       
        if (buttons[i].id == id) {
          buttons[i].classList.add("time-option-active");
        } else {
          buttons[i].classList.remove("time-option-active");
        }
      }
    },
  },
};
</script>