<template>
  <div class="control-panel-lt">
    <div class="control-back"></div>
   <div class="control-row-1">
     
          <v-btn
          id="raffle-option-button-0"
          @click="onRaffleButtonClick(0)"
          class="raffle-option-button"
          elevation="2"
        >
          {{ lblFirstDay }}
        </v-btn>
        <v-btn
          id="raffle-option-button-1"
          @click="onRaffleButtonClick(1)"
          class="raffle-option-button"
          elevation="2"
        >
          {{ lblSecondDay }}
        </v-btn>
     
        <v-btn
          class="control-rooster-button"
          @click="onBlindRooster"
          elevation="2"
          :disabled="disabledControls"
        >
          {{ getPhrase('blind_rooster') }}
        </v-btn>
           <v-btn
          class="control-revenge-button"        
          :class="[revenge ? 'control-revenge-active' : '']"
          @click="onRevenge"
          elevation="2"
         :disabled="disabledControls"
        >
          {{ getPhrase('revenge_bet_type_label') }}
        </v-btn>
    </div> 
    <div class="control-row-2">
 
      <div class="control-col-middle">
        <v-btn
          v-for="(btn, index) of valueButtons"
          :key="index"
          :class="getButtonClass(btn)"
          @click="onButtonValueClick(index)"
          :disabled="disabledControls"
          elevation="2"
        >
          {{ btn }}
        </v-btn>
      </div>
      <div class="control-col-right">
        <button
          class="lot-button control-print-button"
          :disabled="disabledPrint"
          @click="onPrintClick"
          elevation="2"
        >
          <v-icon class="control-button-print-icon">mdi-printer</v-icon>
          {{ getPhrase('print_label_button') }}
          {{ getPhrase('ticket_label_button') }}
        </button>
        <button
          :disabled="acceptDisabled"
          @click="onAceptarClick"
          class="lot-button control-button-aceptar"
        >
          <v-icon class="control-button-aceptar-icon"
            >mdi-keyboard-return</v-icon
          >
          {{ lblAceptar }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
export default {
  name: "ControlPanel",
  data() {
    return {
      lblDisplayNumber: "NUMERO SELECCIONADO",
      lblDisplayBet: "APUESTA",
      lblCancelar: "CANCELAR",
      lblBetDialog: "MONTO DE APUESTA",     
      lblPrimero: "Primero",
      lblTerminacion: "Terminación",
      lblExacto: "Exacto",
      lblReversible: "Reversible",
      lblRaffles: "Sorteos",
      lblTimes: "Horario",
      lblReventados: "Reventados",
      lblPrint: "Imprimir",
      lblTicket: "Ticket",
      currentPadTagert: "currentNumber",
      lblRevenge: "Revancha",
      lblRooster: "Gallo tapado",
      lblBet: "Monto en",
      disabledMatutino: false,
    };
  },
   computed: {
     ...mapGetters([
      'getPhrase',     
      // ...
    ])
  },

  mounted() {
    this.resetValues();
    
  },
  props: [
    "days",
    "times",
    "revenge",
    "betButtons",
    "valueButtons",
    "currency",
    "disabledPrint",
    "acceptDisabled",
    "customBetDisabled",
    "reset",
    "matutinoTime",
    "nocturnoTime",
    "resetCustomBet",
    "currentRaffleDay",    
    "lblAceptar",


    "lblFirstDay",    
    "lblSecondDay",
    "disabledControls",  
    "disabledDateControls", 
    "disabledBetTypes", 
    "setDefaultDay", 
  ],
  watch: {
    reset(val) {
      this.resetValues();
    },
    resetCustomBet(val) {
      this.resetBet(val);
    },
   
    setDefaultDay(val){     
        setTimeout(() => {
            this.setDefault();
          }, 50);
     
    },
  },

  filters: {
    addCurrency(val, currency) {
      if (val == "0" || val == "") {
        return "";
      } else {
        return val + " " + currency;
      }
    },
  },

  methods: {
   
  
     toggleActiveRaffleButton(id) {
      var buttons = document.getElementsByClassName("display-button");
      //console.log("toggleActiveRaffleButton " + buttons.length);
      for (let i = 0; i < buttons.length; i++) {
        //console.log("id " + buttons[i].id);
        if (buttons[i].id == id) {
          //console.log('Agregando clase a  '+buttons[i].id)
          document.getElementById(id).classList.add("display-button-active");
        } else {
          buttons[i].classList.remove("display-button-active");
        }
      }
    },
    setDefault() {

      let wDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth()).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      
      let weekDay = new Date(yyyy, mm, Number(dd) );

      let todayDayWeek  = wDays[weekDay.getDay()];
      
      if(todayDayWeek === "DOM" || todayDayWeek === "LUN"
      || todayDayWeek === "MAR" || todayDayWeek === "MIE" ){
        this.toggleActiveRaffle("raffle-option-button-0"); 
      }else if(todayDayWeek === "JUE" || todayDayWeek === "VIE"  || todayDayWeek === "SAB"){
        this.toggleActiveRaffle("raffle-option-button-1"); 
      }

      //console.log('WeekDay HOY '+wDays[weekDay.getDay()])
    
    },
    getDisabledTimeStatus(time) {
      if (String(time).toLowerCase() == "matutino" && this.disabledMatutino) {
        return true;
      } else {
        return false;
      }
    },
    resetBet(val) {
      var betIndex = this.betButtons.indexOf(String(val));
      if (betIndex > -1) {
        this.toggleActiveBetChip("chip-bet-" + betIndex);
      } else {
        this.toggleActiveBetChip("chip-bet-0");
      }
    },
    resetValues() {
      //console.log("Control Panel Reset");
      this.toggleActiveBetChip("chip-bet-0");
      this.toggleActiveRaffle("btnRaffleOption-0");
     // this.checkRaffleTime();

    },
    createRipple(event) {
      const button = event.currentTarget;
      const circle = document.createElement("span");

      const diameter = Math.max(button.clientWidth, button.clientHeight);
      const radius = diameter / 2;
      circle.style.width = circle.style.height = `${diameter}px`;
      /*  circle.style.left = `${event.clientX - (button.offsetLeft + radius)}px`;
      circle.style.top = `${event.clientY - (button.offsetTop + radius)}px`; */

      circle.style.left = `${button.clientWidth * 0.5 - diameter * 0.5}px`;
      circle.style.top = `${button.clientHeight * 0.5 - diameter * 0.5}px`;

      circle.classList.add("ripple");

      const ripple = button.getElementsByClassName("ripple")[0];

      if (ripple) {
        ripple.remove();
      }
      button.appendChild(circle);
    },
    onPrintClick(event) {
      this.createRipple(event);
      this.$emit("print");
    },
    getButtonClass(label) {
      if (String(label).toLowerCase() == this.$store.getters.getPhrase('delete_label_button').toLowerCase()) {
        return "control-delete-button";
      } else if (String(label).toLowerCase() == "<") {
        return "control-delete-back-button";
      } else {
        return "control-value-button";
      }
    },
    onButtonBetClick(index) {
      this.toggleActiveBetChip("chip-bet-" + index);
      if (index != "d") {
        this.$emit("update-bet", index);
      } else {
        this.$emit("update-custom-bet");
      }
    },
    onButtonValueClick(index) {
      this.$emit("keyboard-update", index);
    },
    onRaffleButtonClick(index) {
      this.toggleActiveRaffle("raffle-option-button-" + index);
      this.$emit("on-update-raffle-day", index);
     
    },
    onRaffleTimeButtonClick(index) {
     
      this.toggleActiveRaffleTime("btnRaffleTimeOption-" + index);
      this.$emit("on-update-raffle-time", index);
     
    },
    onRaffleDayClick(index) {
     
      this.toggleActiveRaffleTime("btnRaffleTimeOption-" + index);
      this.$emit("on-update-raffle-day", index);
     
    },

    onAceptarClick(event) {
      this.createRipple(event);
      this.$emit("on-aceptar-tap");
    },
    onRevenge() {
      this.$emit("on-revenge-tap");
    },
    onBlindRooster() {
      this.$emit("on-blind-rooster-tap");
    },

    toggleActiveBetChip(id) {
      var chips = document.getElementsByClassName("bet-chip");
      for (let i = 0; i < chips.length; i++) {
        if (chips[i].id == id) {
          document.getElementById(id).classList.add("bet-chip-active");
        } else {
          chips[i].classList.remove("bet-chip-active");
        }
      }
    },
    toggleActiveRaffle(id) {
      var buttons = document.getElementsByClassName("raffle-option-button");

      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id == id) {
          buttons[i].classList.add("raffle-option-active");
        } else {
          buttons[i].classList.remove("raffle-option-active");
        }
      }
    },
    toggleActiveRaffleTime(id) {
      var buttons = document.getElementsByClassName("time-option-button");
     
      for (let i = 0; i < buttons.length; i++) {
       
        if (buttons[i].id == id) {
          buttons[i].classList.add("time-option-active");
        } else {
          buttons[i].classList.remove("time-option-active");
        }
      }
    },
  },
};
</script>