<template>
  <div class="info-panel-tm">
    <div class="info-panel-title">
      <v-img
        alt="Logo"
        class="game-logo"
        src="/static/dist/img/tresMonazosLogo.18016002.png"
        transition="scale-transition"
        
      />
    </div>
    <div class="info-panel-display">
      <div class="end-panel"></div>
      <div
        v-for="(num, index) of numbers"
        :key="index"
        class="info-panel-number"
      >
        <div class="number-container">
          <div class="index">{{ getIndex(index) }}</div>
          <div class="number">{{ num.number }}</div>
        </div>

        <div v-if="num.blindRooster === ''" class="type">
          {{ num.type | capitalized }}
          <div class="date">{{ num.date }}</div>
        </div>
        <div v-else class="type">
          {{ num.type | capitalized }} ({{ num.blindRooster }})
          <div class="date">{{ num.date }}</div>
        </div>

        <div v-if="num.reventadoBet === ''" class="bet">
          {{ num.bet | addCurrency(currency, true) }}
        </div>
        <div v-else class="bet">
          {{ num.bet | addCurrency(currency, true) }}
          <p class="label-reventado">(R)</p>
          {{ num.reventadoBet | addCurrency(currency, true) }}
        </div>
        <div class="options">
          <v-btn class="option-button" icon @click="onDeleteClick(index)">
            <v-icon class="option-icon" color="black">mdi-close</v-icon>
          </v-btn>
        </div>
      </div>
    </div>

    <div class="panel-divider"></div>
    <div class="info-panel-display-totals">
      <p class="info-panel-totals-title">{{ getPhrase('ticket_resume_label') }}</p>
      <!-- <p class="info-panel-totals">{{ lblSorteos }}</p>
      <p class="info-panel-totals-value">{{ raffleTimes }}</p>
      <p class="info-panel-totals">{{ lblDays }}</p>
      <p class="info-panel-totals-value">{{ raffleDays }}</p> -->
      <p class="info-panel-totals">
        {{ getPhrase('ticket_total_amount_label') | addCurrency(currency, false) }}
      </p>
      <p class="info-panel-totals-value">{{ totalBet }}</p>
    </div>
    <div class="info-panel-controls">
      <v-btn
        @click="onClearClick"
        color="error"
        elevation="2"
        class="info-panel-button"
      >
        {{ getPhrase('clear_label_button') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "InfoPanel",
  data() {
    return {
      lblGameLogo: "Game Logo",
      lblClear: "LIMPIAR",
      lblRooster: "GALLO CIEGO",
      lblImprimir: "IMPRIMIR",
      lblSorteos: "Sorteos",
      lblEditar: "Editar",
      lblEliminar: "Eliminar",
      lblTotalsTitle: "Resumen",
      lblTotalBet: "",
      offset: true,
      currentGameLogoSrc: "",
    };
  },
  props: ["numbers", "currency", "totalBet", "raffleDays", "raffleTimes"],
  created() {
    this.lblSorteos = "Sorteos: ";
    this.lblDays = "Dias: ";
    this.lblTotalBet = "Total apostado en";
    this.currentGameLogoSrc = "../assets/nuevosTiemposLogo.png";
  },
  filters: {
    capitalized(val) {
      return val.charAt(0).toUpperCase() + val.slice(1);
    },
    addCurrency(val, currency, before) {
      if (val != "0" && val != "") {
        if (before) {
          return currency + " " + val;
        } else {
          return val + " " + currency;
        }
      } else {
        return val;
      }
    },
  },
   computed:{   
    ...mapGetters([
      'getPhrase',     
      // ...
    ])
  },
  methods: {
    getBetClass(bet, reventadoBet) {
      if (String(bet).length > 5 || String(reventadoBet).length > 5) {
        return "bet big-bet";
      } else {
        return "bet";
      }
    },
    onDeleteClick(index) {      
      this.$emit("delete-number", index);
    },
    getIndex(index) {
      var alphabet = [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ];
      return alphabet[index];
    },
    onClearClick() {
      this.$emit("clear-numbers");
    },
  },
};
</script>