<template>
  <div class="display-panel-lt">
    <div class="display-back"></div>
    <div class="display-row">
      <div class="display-element">
        <div class="number-display">{{
          firstNumber
        }}</div>
      </div>
      <div class="display-element">
        <div class="number-display">{{
          secondNumber
        }}</div>
      </div>
      <div class="display-element">
        <div class="number-display">{{
          thirdNumber
        }}</div>
      </div>
      <div class="display-element">
        <div class="number-display">{{
          fourthNumber
        }}</div>
      </div>
      <div class="display-element">
        <div class="number-display">{{
          fifthNumber
        }}</div>
      </div>
      <div class="display-element">
        <p class="number-bet-label">{{ getPhrase('bet_amount_label') | addCurrency(currency) }}</p>
        <p class="number-bet">{{ amountBet }}</p>
        <!-- <v-fade-transition>
         <v-btn id="btnConfirmarBet"  v-show="showBetConfirm" @click="onConfirmarBetTap" class="confirmar-button">{{
          lblConfirmarBet
        }}</v-btn>
        </v-fade-transition> -->
      </div>
     <!--  <div class="display-element">
        <p class="number-bet-label">{{ lblReventadoBet }}</p>
        <p @click="onReventadoBetTap" class="number-bet">{{ reventadoAmountBet }}</p>
        <v-btn id="btnReventado" :disabled="getDisabledStatus()" @click="onReventadoTap" class="reventado-button">{{
          lblReventados
        }}</v-btn>
      </div> -->
     
    </div>
    <div class="display-element-notifications">
      
      <p  class="notifications-title">{{ getPhrase('notifications_label') }}</p>
      <div class="notifications-alert" :class="getAlertTitleClass()">
        <v-icon v-if="alertType==='info'" class="alert-icon-info">mdi-exclamation-thick</v-icon>
        <v-icon v-if="alertType==='error'" class="alert-icon-error">mdi-alert</v-icon>
        <p class="alert-text">{{ alertText }}</p>
      </div>
    </div>
    <!-- <div class="controls-row">
      <p class="controls-label">{{ lblControls }}</p>
      <div class="controls-container">
        <v-btn
          id="btnOrden"
          @click="onOrdenClick"
          class="display-button"
          elevation="2"
        >
          {{ lblOrden }}
        </v-btn>
        <v-btn
          id="btnDesorden"
          @click="onDesordenClick"
          class="display-button"
          elevation="2"
        >
          {{ lblDesorden }}
        </v-btn>
        <v-btn
          id="btnOrdDes"
          @click="onOrdDesClick"
          class="display-button"
          elevation="2"
        >
          {{ lblOrdenDesorden }}
        </v-btn>
        <v-btn
          id="btnUltimos"
          @click="onUltimosClick"
          class="display-button"
          elevation="2"
        >
          {{ lblOrdenUltimos }}
        </v-btn>
      </div>
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DisplayPanel",
  data() {
    return {
      lblOrden: "Orden",
      lblDesorden: "Desorden",
      lblOrdenDesorden: "Orden/Desorden",
      lblOrdenUltimos: "Orden/Ultimos 2",
      lblRafflesTitle: "Sorteos",
      lblRaffles: "Sorteo",
      lblDays: "Dias de la semana",
      lblReventados: "Reventados",
      lblDisplayBet: "Valor de apuesta en ",
      lblReventadoBet: "Apuesta reventados",
      lblNotifications: "Notificaciones",
      lblControls: "Tipo de apuesta", 
      lblConfirmarBet: "Confirmar", 
      reventado:false,  
      currentRaffleType:'', 
    
    };
  },
  props: [
  "firstNumber", 
  "secondNumber", 
  "thirdNumber", 
  "fourthNumber", 
  "fifthNumber", 
  "amountBet",
  "reventadoAmountBet",
  "alertType",
  "alertText",
  "currency",
  "reset",
  "showBetConfirm",
  "disabledConfirmarBet",
  "disabledReventado"
  ],
  watch: {
    reset(val){
      this.resetValues();
    },
    reventadoAmountBet(val){
      if(val == '-'){
        this.lblReventados = 'Confirmar';
      }
    }
   },
   computed:{
      ...mapGetters([
      'getPhrase',     
      // ...
    ])
   },
  created() {
    
  },
  mounted() {
    this.toggleActiveRaffle("btnOrden");
    this.updateRaffle("Orden");
  },
  filters: {
    addCurrency(val,currency) {
      if (val == "0" || val == "") {
        return "";
      } else {
        return val + " "+currency;
      }
    },
  },
  methods: {   
    onBetTap(){
      this.$emit('on-bet'); 
    },
    onReventadoBetTap(){
      this.$emit('on-reventado-bet'); 
      if(this.reventado){
        this.lblReventados = 'Confirmar';  
      }
          
    },
    onConfirmarBetTap(){
      this.$emit('on-confirmar-bet');
    },
    getDisabledStatus(){
      
      if(String(this.currentRaffleType).toLowerCase() == 'exacto' && this.currentNumber != '' && !this.disabledReventado){
        return false;
      }else{
        return true;
      }
    },
    resetValues(){
      this.reventado = false;
      this.lblReventados = 'Reventados';
      
      
    },
    
    getAlertTitleClass(){
      if(this.alertType == 'error'){
        return 'notifications-alert notification-alert-error';
      }else{
        return 'notifications-alert  notification-alert-info';
      }
    },
    updateRaffle(raffle) { 
      this.currentRaffleType = raffle;     
      this.$emit("update-raffle-type", raffle);
    },
    onOrdenClick() {
      
      this.toggleActiveRaffle("btnOrden");
      this.updateRaffle("Orden");      
    },
    onDesordenClick() {
     
      this.toggleActiveRaffle("btnDesorden");
      this.updateRaffle("Desorden");      
    },
    onOrdDesClick() {
      this.toggleActiveRaffle("btnOrdDes");
      this.updateRaffle("Ord/Des");      
    },
    onUltimosClick() {
      
      this.toggleActiveRaffle("btnUltimos");
      this.updateRaffle("Ultimos");
     
    },    
    onReventadoTap() {
      if(this.reventado){
        if(this.lblReventados == 'Confirmar'){
          this.lblReventados = 'Reventados'; 
          this.$emit('on-confirmar-bet');
        }else{
           this.setReventado(false);
        }
        
      }else{
        this.lblReventados = 'Confirmar'; 
        this.setReventado(true);
      }
         
       
       
    },
    setReventado(state){
      this.reventado = state;      
      this.toggleActiveReventado(this.reventado);
      this.$emit("update-reventado",this.reventado);  
    },
    toggleActiveRaffle(id) {
      var buttons = document.getElementsByClassName("display-button");
      for (let i = 0; i < buttons.length; i++) {
        if (buttons[i].id == id) {
          document.getElementById(id).classList.add("display-button-active");
        } else {
          buttons[i].classList.remove("display-button-active");
        }
      }
    },
    toggleActiveReventado(active) {
      var button = document.getElementById("btnReventado");      
      if(active){
        button.classList.add("reventado-button-active");
      }else{
         button.classList.remove("reventado-button-active");
      }    
      
    },
   
  },
};
</script>

