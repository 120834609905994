<template>
  <div class="display-panel-portrait-lt">
   

    <div class="display-element-portrait">
      <div class="number-display-portrait">       
        <span v-if="firstNumber">{{ firstNumber | addCero }}</span>
        <v-img
        v-show="portraitFirstImage"  
        class="display-element-image"          
        src= "/static/dist/img/lottoLogo.6e8555b5.png"
        ></v-img>
      </div>
    </div>
    <div class="display-element-portrait">
      <div class="number-display-portrait">        
        <span v-if="secondNumber">{{ secondNumber | addCero}}</span>
        <v-img
        v-show="portraitSecondImage"   
        class="display-element-image"          
        src= "/static/dist/img/lottoLogo.6e8555b5.png"
        ></v-img>
      </div>
    </div>
    <div class="display-element-portrait">
      <div class="number-display-portrait">       
         <span v-if="thirdNumber">{{ thirdNumber | addCero}}</span>
        <v-img
         v-show="portraitThirdImage"    
        class="display-element-image"          
        src= "/static/dist/img/lottoLogo.6e8555b5.png"
        ></v-img>
      </div>
    </div>
    <div class="display-element-portrait">
      <div class="number-display-portrait">
       
         <span v-if="fourthNumber">{{ fourthNumber | addCero}}</span>
        <v-img
        v-show="portraitFourthImage"  
        class="display-element-image"          
        src= "/static/dist/img/lottoLogo.6e8555b5.png"
        ></v-img>
      </div>
    </div>
    <div class="display-element-portrait">
      <div class="number-display-portrait">
        
         <span v-if="fifthNumber">{{ fifthNumber | addCero}}</span>
        <v-img
        v-show="portraitFifthImage"      
        class="display-element-image"          
        src= "/static/dist/img/lottoLogo.6e8555b5.png"
        ></v-img>
      </div>
    </div>
    <div class="display-element-portrait">
      <p class="number-bet-label">
        {{ getPhrase('bet_amount_label') | addCurrency(currency) }}
      </p>
      <p @click="onBetTap" class="number-bet">{{ amountBet }}</p>
      <v-fade-transition>
        <v-btn
          id="btnConfirmarBet"
          v-show="showBetConfirm"
          @click="onConfirmarBetTap"
          class="confirmar-button"
          >{{ lblConfirmarBet }}</v-btn
        >
      </v-fade-transition>
    </div>
   <!--  <div class="display-element-portrait">
      <p class="number-bet-label">{{ lblReventadoBet }}</p>
      <p @click="onReventadoBetTap" class="number-bet">
        {{ reventadoAmountBet }}
      </p>
      <v-btn
        id="btnReventado"
        :disabled="getDisabledStatus()"
        @click="onReventadoTap"
        class="reventado-button"
        >{{ lblReventados }}</v-btn
      >
    </div>  -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "DisplayPanel",
  data() {
    return {
      lblPrimero: "Primero",
      lblTerminacion: "Terminación",
      lblExacto: "Exacto",
      lblReversible: "Reversible",
      lblRafflesTitle: "Sorteos",
      lblRaffles: "Sorteo",
      lblDays: "Dias de la semana",
      lblReventados: "Reventados",
      lblDisplayBet: "Valor de apuesta en ",
      lblReventadoBet: "Apuesta reventados",
      lblNotifications: "Notificaciones",
      lblControls: "Tipo de apuesta",
      lblConfirmarBet: "Confirmar",
      reventado: false,     
    };
  },
  props: [
    "firstNumber", 
    "secondNumber", 
    "thirdNumber", 
    "fourthNumber", 
    "fifthNumber", 
    "currentNumber",
    "amountBet",
    "reventadoAmountBet",    
    "currency",
    "reset",
    "showBetConfirm",
    "disabledConfirmarBet",
    "disabledReventado",
    "currentRaffleType",
    "portraitFirstImage",
    "portraitSecondImage",
    "portraitThirdImage",
    "portraitFourthImage",
    "portraitFifthImage",
  ],
  watch: {
    reset(val) {
      this.resetValues();
    },
    reventadoAmountBet(val) {
      if (val == "-") {
        this.lblReventados = "Confirmar";
      }
    },
    currentRaffleType(){

      switch (String(this.currentRaffleType).toLowerCase()) {
        case 'primero':
        case 'terminación':
        case 'reversible':
          //this.setReventado(false);
          break;
      
        default:
          break;
      }

    }
  },
 
  filters: {
    addCurrency(val, currency) {
      if (val == "0" || val == "") {
        return "";
      } else {
        return val + " " + currency;
      }
    },
    addCero(val){
      if(String(val).length === 1){
        return '0'+val;
      }else{
        return val;
      }
    }
  },
   computed:{
      ...mapGetters([
      'getPhrase',     
      // ...
    ])
   },
  methods: {
    onBetTap() {
      this.$emit("on-bet");
    },
    onReventadoBetTap() {
      this.$emit("on-reventado-bet");
      if (this.reventado) {
        this.lblReventados = "Confirmar";
      }
    },
    onConfirmarBetTap() {
      this.$emit("on-confirmar-bet");
    },
    getDisabledStatus() {
      if (
        String(this.currentRaffleType).toLowerCase() == "exacto" &&
        this.currentNumber != "" &&
        !this.disabledReventado
      ) {
        return false;
      } else {
        return true;
      }
    },
    resetValues() {
      this.reventado = false;
      this.lblReventados = "Reventados";
      //this.toggleActiveReventado(this.reventado);
    },     
    onReventadoTap() {
      if (this.reventado) {
        if (this.lblReventados == "Confirmar") {
          this.lblReventados = "Reventados";
          this.$emit("on-confirmar-bet");
        } else {
          this.setReventado(false);
        }
      } else {
        this.lblReventados = "Confirmar";
        this.setReventado(true);
      }
    },
    setReventado(state) {
      this.reventado = state;
      this.toggleActiveReventado(this.reventado);
      this.$emit("update-reventado", this.reventado);
    },
   
    toggleActiveReventado(active) {
      var button = document.getElementById("btnReventado");
      if (active) {
        button.classList.add("reventado-button-active");
      } else {
        button.classList.remove("reventado-button-active");
      }
    },
  },
};
</script>

